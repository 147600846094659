import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledPetEditDetails = styled(Box)`
.linktext{
  color: #000;
  &:hover{
    color: #f6911e;
  }
}
.pet-link{
    text-decoration: none;
    color: #131C23 !important;
    display: flex;
    align-items: center;
    font-style: initial;
    font-weight: 600;
    svg{
        font-size: 2rem;
        margin-right: 10px;
    }
}
  .pet-img-block{
    width: 280px;
    height: 280px;
    border-radius: 50%;
    background-color: #fff;
    overflow: hidden;
    margin: 0 auto;
  }
  .detail-row{
    display: flex;
    margin-bottom: 7px;
    p:nth-child(1){
        min-width: 211px;
    }
    p:nth-child(2){
        font-weight: 500;
    }
  }
  @media (min-width: 900px){
   .pet-img-block{
    margin:0;
    & + div{
        padding-left: 310px;
      }
   }
  }


`;
