import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import { StyledNavItem, StyledHeaderNav, StyledProfileNav } from './styles';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Button,
  Container,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalRoutes } from '../../../types/routes';
import { logout } from '../../../redux/auth/authStore';
import { removeAuthToken } from '../../../utils/sessions';
import PersonIcon from '@mui/icons-material/Person';
import { Message } from '../../../config/messages';
import { setUserData } from '../../../redux/user/userStore';
import { navItems } from '../../../config/navItems';
import { useToast } from '../../../hooks/useToast';
import { RootState, persistor } from '../../../redux';
import { toggleEditing } from '../../../redux/textEdit/editorSlice';
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

export default function DrawerAppBar(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { window: screen } = props;
  const { editable } = useSelector((state: RootState) => state.auth);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const toast = useToast();
  const { token } = useSelector((state: RootState) => state.auth);
  const { userData } = useSelector((state: RootState) => state.user);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { pathname } = useLocation();
  const { editing } = useSelector((state: RootState) => state.textEdit);
  const { completed } = useSelector((state: RootState) => state.stepper);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };


  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userLogout = async () => {
    try {
      await persistor.purge();
      localStorage.clear();
      window.location.href = GlobalRoutes.HOME;
    } catch (err) {
      // handle error
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box
        sx={{
          maxWidth: '85%',
          p: 1,
          marginLeft: 'auto',
          marginRight: 'auto',
          height: '65px',
        }}
        component="img"
        alt="The house from the offer."
        src="/assets/icons/My-Pets-Text-Horizontal.svg"
        className="img-style img-style__contain"
      />
      <Divider />
      <List>
        {navItems.map(
          ({ title, path, stepperCount, isProtected = false }, index) => {
            return isProtected ? (
              token && stepperCount as number <= completed ? (
                <Box component={NavLink} to={path} key={index}>
                  <ListItem disablePadding>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                      <StyledNavItem
                        style={({ isActive }) => ({
                          fontWeight: isActive ? '700' : '',
                          color: '#131c23', 
                          textAlign: 'center', 
                          display: 'inline-block', 
                          width: '100%', 
                        })}
                        to={path}
                      >
                        {title}
                      </StyledNavItem>
                    </ListItemButton>
                  </ListItem>
                </Box>
              ) : null
            ) : (
              <Box component={NavLink} to={path} key={index}>
                <ListItem disablePadding>
                  <ListItemButton sx={{ textAlign: 'center' }}>
                    <StyledNavItem
                      style={({ isActive }) => ({
                        fontWeight: isActive ? '700' : '',
                        color: '#131c23', 
                        textAlign: 'center',
                        display: 'inline-block', 
                        width: '100%', 
                      })}
                      to={path}
                    >
                      {title === 'Showcase' ? (token ? 'Home' : title) : title}
                    </StyledNavItem>
                  </ListItemButton>
                </ListItem>
              </Box>
            );
          }
        )}
      </List>
    </Box>
  );

  const container =
    screen !== undefined ? () => screen().document.body : undefined;

  return (
    <AppBar sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          boxShadow: '0px 4px 13px 0px rgba(175, 175, 175, 0.30)',
          bgcolor: 'primary.contrastText',
          color: 'primary.dark',
        }}
      >
        <Container fixed maxWidth="xl">
          <StyledHeaderNav>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml: 0, mr: 1, display: { md: 'none' } }}
            >
              <Box component="img" alt="Bars." src="/assets/icons/bars.svg" />
            </IconButton>
            <Link to="/">
              <Box
                sx={{ maxWidth: { xs: '150px', lg: '200px' }, height: '62px' }}
                component="img"
                src="/assets/icons/My-Pets-Text-Horizontal.svg"
                alt="Logo"
                className="img-style img-style__contain logo-wrap"
              />
            </Link>

            <Box sx={{ display: 'flex' }}>
              <Box
                className="menu-list"
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'flex',
                    alignItems: 'center',
                  },
                }}
              >
                {navItems.map(
                  (
                    { title, stepperCount, path, isProtected = false },
                    index
                  ) => {
                    return isProtected ? (
                      token && (stepperCount as number) <= completed ? (
                        <StyledNavItem
                          style={({ isActive }) => {
                            return {
                              fontWeight: isActive ? '700' : '',
                            };
                          }}
                          to={path}
                          key={index}
                        >
                          {title}
                        </StyledNavItem>
                      ) : null
                    ) : (
                      <StyledNavItem
                        style={({ isActive }) => {
                          return {
                            fontWeight: isActive ? '700' : '',
                          };
                        }}
                        to={path}
                        key={index}
                      >
                        {title === 'Showcase'
                          ? token
                            ? 'Home'
                            : title
                          : title}
                      </StyledNavItem>
                    );
                  }
                )}
              </Box>
            </Box>
            {token ? (
              <Box sx={{ display: 'flex'}} className="account-info">
                <Stack
                  justifyContent="center"
                  sx={{ display: { xs: 'none', sm: 'flex' } }}
                >
                  <Typography component="span" variant="h6" fontWeight={400}>
                    Welcome,
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontWeight: '700' }}
                    variant="h6"
                  >
                    {userData?.user
                      ? userData.user?.first_name +
                        ' ' +
                        userData.user?.last_name
                      : ''}
                  </Typography>
                </Stack>
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={handleClick}
                  size="small"
                  aria-controls={open ? 'account-menu' : undefined}
                  disableRipple
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>
                    {!userData?.profile_pic ? (
                      userData?.user?.first_name ? (
                        userData?.user?.first_name.charAt(0).toUpperCase()
                      ) : (
                        <PersonIcon />
                      )
                    ) : (
                      <Box
                        component="img"
                        alt="user-image"
                        src={userData?.profile_pic}
                        className="img-style__contain shape-top"
                      />
                    )}
                  </Avatar>
                  <KeyboardArrowDownIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <StyledProfileNav> 
                    <Box className="profile-menu">
                      <Link
                        to={GlobalRoutes.ACCOUNT_PROFILE}
                        style={{ fontStyle: 'normal' }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          sx={{
                            fontSize: {
                              xs: '13px',
                              sm: '14px',
                              md: '15px',
                              lg: '16px',
                            },
                          }}
                        >
                          My Account
                        </MenuItem>
                      </Link>
                      <Link
                        to={GlobalRoutes.MY_GALLERY}
                        style={{ fontStyle: 'normal' }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          sx={{
                            fontSize: {
                              xs: '13px',
                              sm: '14px',
                              md: '15px',
                              lg: '16px',
                            },
                          }}
                        >
                         My Gallery
                        </MenuItem>
                      </Link>
                      <Link
                        to={GlobalRoutes.CHANGE_PASSWORD}
                        style={{ fontStyle: 'normal' }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          sx={{
                            fontSize: {
                              xs: '13px',
                              sm: '14px',
                              md: '15px',
                              lg: '16px',
                            },
                          }}
                        >
                          Change Password
                        </MenuItem>
                      </Link>
                      <MenuItem
                        onClick={userLogout}
                        sx={{
                          fontSize: {
                            xs: '13px',
                            sm: '14px',
                            md: '15px',
                            lg: '16px',
                          },
                        }}
                      >
                        Logout
                      </MenuItem>
                      {editable && (
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            dispatch(toggleEditing());
                          }}
                          sx={{
                            fontSize: {
                              xs: '13px',
                              sm: '14px',
                              md: '15px',
                              lg: '16px',
                            },
                          }}
                        >
                          {editing ? 'Stop Text Editing' : 'Edit Text'}
                        </MenuItem>
                      )}
                    </Box>
                  </StyledProfileNav>
                </Menu>
                <Link
                  to=""
                  style={{ lineHeight: 0, padding: '8px' }}
                  className="notification-action"
                >
                  <Box
                    component="img"
                    alt="notification icon"
                    src="/assets/icons/icon _notifications none.svg"
                  />
                </Link>
              </Box>
            ) : (
              <Button
               sx={{ml:{xs:'auto',md:0}}}
                onClick={() => navigate(GlobalRoutes.LOGIN)}
                disabled={pathname.includes('login')}
                variant="contained"
                color="primary"
              >
                Login
              </Button>
            )}
          </StyledHeaderNav>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </AppBar>
  );
}
