import { useNavigate } from 'react-router-dom';
import { GlobalRoutes } from '../../../types';
import { Button, Typography, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

function PaymentCancelPage() {
  const navigate = useNavigate();
  return (
    <Box sx={{ textAlign: 'center', padding: '6rem 1.5rem' }}>
      <Box
      className="container-wrap"
        sx={{
          backgroundColor: 'info.lighter',
          p: 4,
          pb: 5,
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }} color="warning.main">
          <CancelIcon
            sx={{ fontSize: '1.9rem', top: '4px', position: 'relative', mr: 1 }}
          />
          Subscription canceled!
        </Typography>
        <Typography variant="body1">
          It seems you have canceled the payment. You can retry or return to the
          homepage.
        </Typography>
        <Box>
          <Button variant="contained" sx={{ mx: 2, mt: 4 }} color="primary" onClick={() => navigate(GlobalRoutes.ACCOUNT_PROFILE)}>
            Back to Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentCancelPage;
