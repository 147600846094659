interface Plan {
    id: string;
    name: string;
    description: string;
    product_detail: string;
    monthly_price: string;
    monthly_price_id: string | null;
    yearly_price: string;
    yearly_price_id: string | null;
    currency: string;
    order: string;
  }
  
  interface PricingResponse {
    annually: PricingItemsType[];
    monthly: PricingItemsType[];
  }
  
  export type PricingItemsType = {
    title: string;
    price: string;
    priceSubtitle: string;
    features: string[]; 
    creditsText: string | null;
    description: string;
    priceId: string | null;
    order: string;
}
  
  function generatePricingResponse(plans: Plan[]): PricingResponse {
    const annually = [
      {
        title: 'Free Trial',
        price: '0.00',
        priceSubtitle: 'per user/month, billed annually',
        features: [
          "20 free credits, one time for free.",
          "Your 7-day Free Trial offers a chance to see what the excitement is all about. Get a text message with image every day for a week, completely free.",
          "Try it, we'll think you'll like it!",
        ],
        creditsText: 'You get 100 free credits.',
        description: 'Join our Alpha Testing team for 30 days.  Provide us feedback on how to improve our messages and platform.',
        priceId: null,
        order: '1'
      },
      ...plans.map((plan) => ({
        title: plan.name,
        price: plan.yearly_price,
        priceSubtitle: 'per user/month, billed annually',
        features: plan.product_detail.split('!#!').map((feature) => feature.trim()),
        creditsText: null,
        description: plan.description,
        priceId: plan.yearly_price_id,
        order: plan.order
      })),
    ].sort((a, b) => parseInt(a.order) - parseInt(b.order));
  
    const monthly = [
      {
        title: 'Free Trial',
        price: '0.00',
        priceSubtitle: 'per user/month, billed monthly',
        features: [
          "20 free credits, one time for free.",
          "Your 7-day Free Trial offers a chance to see what the excitement is all about. Get a text message with image every day for a week, completely free.",
          "Try it, we'll think you'll like it!",
        ],
        creditsText: 'You get 100 free credits.',
        description: 'Join our Alpha Testing team for 30 days.  Provide us feedback on how to improve our messages and platform.',
        priceId: null,
        order: '1'
      },
      ...plans.map((plan) => ({
        title: plan.name,
        price: plan.monthly_price,
        priceSubtitle: 'per user/month, billed monthly',
        features: plan.product_detail.split('!#!').map((feature) => feature.trim()),
        creditsText: null,
        description: plan.description,
        priceId: plan.monthly_price_id,
        order: plan.order
      })),
    ].sort((a, b) => parseInt(a.order) - parseInt(b.order));
  
    return { annually, monthly };
  }
  
  export default generatePricingResponse;