import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 700px;
  background-color: #FFFFFF; 
  padding: 20px;
  width: 90%;
  border-radius: 10px;
  box-shadow: 0px 0px 18.9px 0px #A7A7A740;
  p: 3;
  display: flex; 
  flex-direction: column;
  justify-content: center; 
  align-items: center;
`;

export const TitleContainer = styled('h1')`
  font-size: 22px;
  font-weight: 600px;
  margin: 0;
`;

export const SubtitleContainer = styled('span')`
  font-size: 14px;
  font-weight: 500px;
`;