import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSocialShare = styled(Box)`

  .share-btn {
    border: 1px solid #131c23;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff !important;
    &[aria-describedby='simple-popper'] {
      color: #f6911e;
    }
  }

`;
