import React, { useState, useEffect } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

interface CustomTextFieldProps extends Omit<TextFieldProps, 'value' | 'onChange'> {
    value?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
    value,
    onChange,
    ...props
}) => {
    const [displayValue, setDisplayValue] = useState<string>(value || '');

    useEffect(() => {
        if (value) {
            setDisplayValue(value);
        }
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setDisplayValue(newValue);
        onChange({
            ...event,
            target: {
                ...event.target,
                value: newValue,
            }
        });
    };
    return (
        <TextField
            {...props}
            value={displayValue}
            onChange={handleChange}
        />
    );
};
export default CustomTextField;
