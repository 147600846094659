import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledPricingCard = styled(Box)`
height: 100%;
.MuiListItem-root {
  align-items: flex-start;
}
.MuiListItemIcon-root {
  position: relative;
  top: 5px;
}

  .selected-plan {
    font-weight: 800;
  }
  .price-block a:not(.MuiButton-root) {
    text-decoration: underline;
  }
  .price-block__content {
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 400 !important;
    line-height: 21px !important;
  }
  .price-block__list {
    width: 100%;
  }
  .price-block__list img {
    width: 18px;
    height: 18px;
  }
  .price-block__list {
    margin-bottom: 2rem;
  }
  .price-block__list li {
    padding: 3px 0;
  }

  .price-block__list .MuiTypography-body1 {
    font-size: 14px;
    line-height: 21px;
    color: #676767;
  }

  .price-block__list li .MuiListItemIcon-root {
    min-width: 32px;
  }
  .price-block.selected {
    background-color: #d9f5f3;
  }

  .price-block.selected .selected-plan {
    display: block;
  }

  @media (max-width: 599px) {
    .price-block {
      align-items: center;
    }
    .price-block__sub {
      max-width: 185px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
