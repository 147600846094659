import React, { useEffect, useState } from "react";
import Header from "../components/Header/layout";
import Footer from "../components/Footer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { setUserData } from "../../redux/user/userStore";
import { useLazyFetchUserWithTokenQuery } from "../../redux/user/userApi";
import { useLazyGetProfileStepperQuery } from "../../redux/stepper/stepperApi";
import { setProfileCompleted } from "../../redux/stepper/stepperStore";
import { Stepper } from "../../config/constants";
import { GlobalRoutes } from "../../types";
import Loader from "../../components/Loader";
import { Box } from "@mui/material";

function AppLayout(): JSX.Element {
  const { token, isAuthenticated } = useSelector((state: RootState) => state.auth);
  const [fetchUserData, { data: userData }] = useLazyFetchUserWithTokenQuery();
  const [fetchStepperData, { data: stepperData }] = useLazyGetProfileStepperQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if(token) {
      fetchUserData(token);
      fetchStepperData(token);
    }
  }, [token]);

  useEffect(() => {
    if(userData) {
      dispatch(setUserData(userData));
    }
    if (stepperData) {
      dispatch(setProfileCompleted(stepperData?.data?.completed));
    }
 
  }, [userData, stepperData]);

  useEffect(() => {
    if (stepperData?.data) {
      if (location && !['/cancel', '/success'].includes(location.pathname)) {
        if (stepperData?.data?.completed < Stepper.MY_CREDIT) {
          handleNavigation(stepperData?.data?.completed);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
  }, [stepperData]);

  function handleNavigation(completedCount: number) {
    let url = GlobalRoutes.EDIT_PROFILE; 
    if(completedCount < Stepper.PROFILE) {
      url = GlobalRoutes.EDIT_PROFILE; 
    } else if(completedCount < Stepper.MY_PETS) {
      url = GlobalRoutes.ACCOUNT_MY_PETS;
    } else if(completedCount < Stepper.SCHEDULE_MESSAGE) {
      url = GlobalRoutes.ACCCOUNT_SCHEDULE_MESSAGE;
    } 
    navigate(url, {
      state: {
        message: true, 
      },
    });
    setLoading(false);
  }

  return (
    <>
      <ScrollToTop/>
      <Header />
      <Box component="main">
      { (loading === false || isAuthenticated === false) ? <Outlet /> : (
        <Box sx={{
          minHeight: '500px'
        }}>
          <Loader />
        </Box>
      )}
      </Box>
      <Footer />
    </>
  );
}

export default AppLayout;
