import AuthLayout from "../layouts/AuthLayout/layout";
import About from "../pages/About/layout";
import Contact from "../pages/Contact/layout";
import Faq from "../pages/Faq/layout";
import Features from "../pages/Features/layout";
import Pricing from "../pages/Pricing/layout";
import HowWorks from "../pages/HowWorks/layout";
import Home from "../pages/Home/layout";
import PrivacyPolicy from "../pages/PrivacyPolicy/layout";
import TermsCondition from "../pages/TermsCondition/layout";
import Testimonial from "../pages/Testimonial/layout";
import ForgotPassword from "../pages/auth/ForgotPassword/layout";
import Login from "../pages/auth/Login/layout";
import Register from "../pages/auth/Register/layout";
import ResetPassword from "../pages/auth/ResetPassword/layout";
import EditProfile from "../pages/my-account/EditProfile/layout";
import MyAccount from "../pages/my-account/layout";
import ActivateProvider from "../providers/ActivateProvider";
import { GlobalRoutes } from "../types";
import { Navigate } from "react-router-dom";
import PageNotFound from "../components/PageNotFound";
import PetGallery from "../pages/PetGallery/layout";
import PaymentSuccessPage from "../pages/Payment/Success/layout";
import PaymentCancelPage from "../pages/Payment/Cancel/layout";

export const publicRoutes = [
  {
    element: <Home />,
    index: true,
  },
  {
    path: GlobalRoutes.HOWWORKS,
    element: <HowWorks />,
    index: true,
  },
  {
    path: GlobalRoutes.PET_GALLERY,
    element: <PetGallery />,
    index: true,
  },
  {
    path: GlobalRoutes.ABOUT,
    element: <About />,
    index: true,
  },
  {
    path: GlobalRoutes.FEATURES,
    element: <Features />,
    index: true,
  },
  {
    path: GlobalRoutes.PRICING,
    element: <Pricing />,
    index: true,
  },
  {
    path: GlobalRoutes.TESTIMONIAL,
    element: <Testimonial />,
    index: true,
  },
  {
    path: GlobalRoutes.CONTACT,
    element: <Contact />,
    index: true,
  },
  {
    path: GlobalRoutes.FAQ,
    element: <Faq />,
    index: true,
  },
  {
    path: GlobalRoutes.LOGIN,
    element: (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
  },
  {
    path: GlobalRoutes.ACTIVATE_ACCOUNT,
    element: (
      <ActivateProvider>
        <Login />
      </ActivateProvider>
    ),
    children: [
      {
        path: "",
        element: <EditProfile />,
      },
    ],
  },
  {
    path: GlobalRoutes.REGISTER,
    element: (
      <AuthLayout>
        <Register />
      </AuthLayout>
    ),
  },
  {
    path: GlobalRoutes.FORGOT_PASSWORD,
    element: (
      <AuthLayout>
        <ForgotPassword />
      </AuthLayout>
    ),
  },
  {
    path: GlobalRoutes.RESET_PASSWORD,
    element: (
      <AuthLayout>
        <ResetPassword />
      </AuthLayout>
    ),
  },
  {
    path: '/success',
    element: (<PaymentSuccessPage />),
  },
  {
    path: '/cancel',
    element: (<PaymentCancelPage />),
  },
  {
    path: GlobalRoutes.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: GlobalRoutes.TERMS_CONDITIONS,
    element: <TermsCondition />,
  },

];
