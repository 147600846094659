import React from 'react';
import {
  Box,
  Grid,
  Container,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Media } from '../../config/media';
import { StyledContactBlock } from './styles';
import ImageBanner from '../../components/ImageBanner';
import { FormikProps, useFormik } from 'formik';
import { Message } from '../../config/messages';
import { LoadingButton } from '@mui/lab';
import { useContactMutation } from '../../redux/user/userApi';
import { MyPetConstants, QuestionCategories } from '../../config/constants';
import { contactSchema } from '../../validators/schemas/userSchemas';
import { Helmet } from 'react-helmet-async';
import { useToast } from '../../hooks/useToast';
import SocialIcons from '../../components/SocialIcons';

function Contact() {
  const [contact, { isLoading }] = useContactMutation();
  const toast = useToast();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  }: FormikProps<any> = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: contactSchema,
    onSubmit: async ({ name, email, message }, { resetForm }) => {
      try {
        const payload = {
          name,
          email,
          message,
        };
        await contact(payload)
          .unwrap()
          ?.then((res: any) => {
            resetForm();
            toast({
              title: 'Message sent successfully',
              severity: 'success',
            });
          })
          ?.catch((error: any) => {
            toast({
              title: error?.data?.error || Message.SomethingWrong,
              severity: 'error',
            });
          });
      } catch (err: any) {
        toast({
          title: Message.SomethingWrong,
          severity: 'error',
        });
      }
    },
  });

  const sendMail = () => {
    const el = document.createElement('a');
    el.href = `mailto:${MyPetConstants.email}`;
    el.click();
  };

  const { name,  email, message } = values;
  return (
    <>
      <Helmet>
        <title>Contact Us | MypetsText</title>
      </Helmet>
      <ImageBanner img={Media.BannerContactImage} />
      <Box
        sx={{ bgcolor: 'secondary.light' }}
        pt={{ xs: 4, sm: 7, md: 8, lg: 9 }}
        pb={{ xs: 8, sm: 10, md: 12, lg: 14 }}
      >
        <Container fixed maxWidth="xl">
          <Grid item container spacing={{ xs: 2, lg: 12 }}>
            <Grid
              item
              md={6}
              xs={12}
              mb={2}
              sx={{ color: 'primary.contrastText' }}
            >
              <Box
                sx={{
                  position: 'relative',
                  justifyContent: 'flex-start',
                  paddingLeft: { xs: '12%', sm: '27%', md: '18%' },
                }}
                className="flex-center img-style"
              >
                <Box
                  component="img"
                  alt="The shape"
                  src={Media.BubbleImg}
                  className="img-style__contain img-absolute img-style"
                />
                <StyledContactBlock>
                  <Box sx={{ position: 'relative' }} className="contact-info">
                    <Box mb={3}>
                      <Typography variant="h3" mb={1}>
                        Email Address
                      </Typography>
                      <Typography variant="caption" mb={3}>
                        <Box
                          onClick={sendMail}
                          className="maillink"
                          sx={{
                            color: 'primary.contrastText',
                            fontStyle: 'normal',
                            textDecoration: 'none',
                            cursor: 'pointer',
                          }}
                        >
                          {MyPetConstants.email}
                        </Box>
                      </Typography>
                    </Box>
                    <Box mb={3}>
                      <Typography variant="h3" mb={1}>
                        Social Media
                      </Typography>
                 <SocialIcons />
                    </Box>
                  </Box>
                </StyledContactBlock>
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant="h1" mb={2}>
              Contact Us
              </Typography>
              <Typography mb={2}>
                Have questions or feedback? We’d love to hear from you. Fill out
                the form send us an email  or reach out to us on social media.
              </Typography>
              <form autoComplete="off" onSubmit={handleSubmit}>
                <Box>
                  <FormControl
                    sx={{ my: 1.5, width: '100%' }}
                    variant="outlined"
                  >
                    <TextField
                      fullWidth
                      id="name"
                      label="Your Name"
                      variant="outlined"
                      value={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color={errors.name ? 'error' : 'primary'}
                      error={!!(touched.name && errors.name)}
                      helperText={
                        touched.name &&
                        errors.name &&
                        typeof errors.name === 'string' &&
                        errors.name
                      }
                    />
                  </FormControl>
                  <FormControl
                    sx={{ my: 1.5, width: '100%' }}
                    variant="outlined"
                  >
                    <TextField
                      fullWidth
                      id="name"
                      label="Your Email Address"
                      variant="outlined"
                      name="email"
                      value={email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color={errors.email ? 'error' : 'primary'}
                      error={!!(touched.email && errors.email)}
                      helperText={
                        touched.email &&
                        errors.email &&
                        typeof errors.email === 'string' &&
                        errors.email
                      }
                    />
                  </FormControl>
                  {/* <Box>
                    <Select
                     variant="outlined"
                      name="type"
                      fullWidth
                      value={values?.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.type && touched.type)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Select Type
                      </MenuItem>
                      {QuestionCategories.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.replace(/ /g, '_').toLowerCase()}
                        >
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  {!!(touched.type && errors.type) && (
                    <FormHelperText error>{errors.type as string}</FormHelperText>
                  )}
                  </Box> */}
                  <FormControl
                    sx={{ my: 1.5, width: '100%' }}
                    variant="outlined"
                  >
                    <TextField
                      value={message}
                      name="message"
                      label="Message"
                      placeholder="Enter Your Message"
                      multiline
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color={errors.message ? 'error' : 'primary'}
                      error={!!(touched.message && errors.message)}
                      helperText={
                        touched.message &&
                        errors.message &&
                        typeof errors.message === 'string' &&
                        errors.message
                      }
                    />
                  </FormControl>
                  <Box mt={3}>
                    <LoadingButton
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      {isLoading ? (
                        <CircularProgress
                          sx={{ ml: 2 }}
                          color="inherit"
                          size={24}
                        />
                      ) : (
                        `Send Message`
                      )}
                    </LoadingButton>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default Contact;
