import { SetStateAction, useState } from 'react';
import OtpInput from 'react-otp-input';
import { Box, Button, Modal, Typography } from '@mui/material';
import { StyledBox, SubtitleContainer, TitleContainer } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import { useFetchUserQuery } from '../../redux/user/userApi';

type PropsType = {
  open: boolean;
  onClose: () => void;
  verifyingTo: string;
  addressInfo: string;
  subtitle: string;
  otp: string;
  setOtpVerifyStatus: React.Dispatch<React.SetStateAction<boolean>>;
  setOtp: React.Dispatch<React.SetStateAction<string>>;
  sendOtpHandler: (contactNumber: string) => Promise<void>;
  contactNumber: string;
};

const OTPInput: React.FC<PropsType> = ({
  open,
  onClose,
  verifyingTo,
  addressInfo,
  subtitle,
  otp,
  setOtp,
  sendOtpHandler,
  setOtpVerifyStatus,
  contactNumber
}) => {
  // Function to handle resend otp functionality
  const handleResend = () => {
    sendOtpHandler(contactNumber)
  };

  // Function to verify otp
  const verifyOtpHandler = () => {
    setOtpVerifyStatus(true);
  };

  return (
    <Modal
      open={open}
      disableEscapeKeyDown
      onClose={onClose}
      BackdropProps={{
        onClick: (event) => event.stopPropagation(),
      }}
    >
      <StyledBox>
        <span onClick={() => onClose()}>
          <CloseIcon
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
            }}
          />
        </span>
        <TitleContainer>Verify Your {verifyingTo}</TitleContainer>
        <SubtitleContainer>{subtitle}</SubtitleContainer>
        <Typography
          component="h4"
          sx={{
            fontSize: '20px',
            mb: 2,
            fontWeight: 500,
          }}
        >
          {addressInfo}
        </Typography>
        <OtpInput
          value={otp}
          onChange={(otp) => setOtp(otp)}
          numInputs={4}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            width: '40px',
            height: '45px',
            marginRight: '10px',
            borderWidth: '2px',
            fontSize: '18px',
            fontWeight: 400,
          }}
        />
        <Typography sx={{ mt: 2 }}>
          Didn't receive the code?{' '}
          <Box
            component="span"
            sx={{
              color: '#00f',
              cursor: 'pointer',
            }}
            onClick={handleResend}
          >
            Resend
          </Box>
        </Typography>
        <Button
          variant="contained"
          sx={{
            mt: 3,
          }}
          disabled={otp.length < 4}
          onClick={verifyOtpHandler}
        >
          Verify
        </Button>
      </StyledBox>
    </Modal>
  );
};

export default OTPInput;
