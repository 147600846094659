import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close';

export const Label = styled('label')``;

export const ImageInput = styled('input')`
  display: none;
`;

export const UploadProfilePic = styled('div')``;

export const UploadImage = styled(UploadProfilePic)`
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
  & span {
    font-size: 14px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const TriggerModal = styled(UploadImage)`
  justify-content: flex-start;
  border: 1px dashed #01b9af;
  text-align: center;
  min-height: 245px;
  box-shadow: 0px 0px 6.2px 0px #b7b7b740;
  border-radius: 6px;
  height: 100%;
  .pet-img__contain {
    padding: 16px;
    background-color: #fff;
    height: 100%;
    width:100%;
    display: flex;
    flex-direction: column;
  }
  .pet-img__wrap {
    width: 180px;
    max-height: 180px;
    object-position: top;
    border-radius: 13px;
    margin: auto;
  }
 
`;

export const RenderImage = styled('img')`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid grey;
`;

export const EditImageContainer = styled(Box)`
  position: relative;
  width: 200px;
  height: 200px;

  max-width: unset;
  &:hover {
    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      transition: opacity 0.3s ease;
      border-radius: 50%;
    }
    & .edit-delete-img-container {
      display: block;
    }
  }
`;

export const EditIcon = styled(EditNoteIcon)`
  color: #fff;
`;

export const DeleteIcon = styled(CloseIcon)`
  cursor: pointer;
  color: #fff;
`;

export const EditDeleteImgContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
`;
