import {
  IApiResponse,
  IChangePassword,
  IContactNumber,
  IVerifyToken,
} from '../../interfaces/auth';
import {
  CreateUserResponse,
  IContactData,
  IResponseData,
  UpdateUserResponse,
} from '../../interfaces/user';
import generatePricingResponse from '../../utils/plan';
import { appApi } from '../apis/apiSlice';


const authApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ['USERS'],
});

export const authApi = authApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<CreateUserResponse, any>({
      query: (body) => {
        return {
          url: '/user/profiles',
          method: 'PATCH',
          body,
        };
      },
    }),

    updateUser: builder.mutation<UpdateUserResponse, any>({
      query: (body) => ({
        url: 'user/profiles',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['USERS'],
    }),

    changePassword: builder.mutation<IApiResponse, IChangePassword>({
      query: ({ confirm_password, password }) => {
        return {
          url: 'user/update-password',
          method: 'POST',
          body: {
            confirm_password,
            password,
          },
        };
      },
    }),
    sendOTP: builder.mutation<IContactNumber, any>({
      query: ({ contact_number }) => {
        return {
          url: '/user/send-otp',
          method: 'POST',
          body: {
            contact_number,
          },
        };
      },
    }),
    verifyEmail: builder.mutation<IVerifyToken, any>({
      query: ({ email }) => {
        return {
          url: '/user/verify-email',
          method: 'POST',
          body: {
            email,
          },
        };
      },
    }),
    verifyOtp: builder.mutation<IVerifyToken, any>({
      query: ({ otp, token }) => {
        return {
          url: '/user/verify-otp',
          method: 'POST',
          body: {
            otp,
            token,
          },
        };
      },
    }),
    fetchResidences: builder.query({
      query: () => ({ url: '/user/residences' }),
    }),

    fetchNeighbourhoods: builder.query({
      query: () => ({ url: '/user/neighbourhoodtypes' }),
    }),

    fetchStateCities: builder.query({
      query: (query) => ({
        url: '/user/citystate/' + `?zip_code=${query}`,
      }),
    }),

    fetchUser: builder.query<any, any>({
      query: () => ({
        method: 'GET',
        url: '/user/profiles',
        keepUnusedDataFor: 0,
        refetchOnMountOrArgChange: true,
      }),
      providesTags: ['USERS'],
    }),

    fetchUserWithToken: builder.query<any, any>({
      query: (token) => ({
        method: 'GET',
        url: '/user/profiles',
        providesTags: ['USERS'],
        Authorization: `Token ${token}`,
      }),
    }),

    contact: builder.mutation<IResponseData, IContactData>({
      query: ({ name, email, message }) => {
        return {
          url: '/contact-us/',
          method: 'POST',
          body: {
            name,
            email,
            message,
          },
        };
      },
    }),
    fetchPlans: builder.query({
      query: () => ({
        url: '/user/products',
        method: 'GET',
      }),
      transformResponse: (response: { data: any }) => generatePricingResponse(response.data),
    }),
    createCheckoutSession: builder.mutation<any, any>({
      query: ({ priceId }) => {
        return {
          url: '/user/create-checkout-session/',
          method: 'POST',
          body: { price_id: priceId },
        };
      },
    }),
  }),
});

export const {
  useFetchUserQuery,
  useCreateUserMutation,
  useLazyFetchUserWithTokenQuery,
  useSendOTPMutation,
  useUpdateUserMutation,
  useVerifyOtpMutation,
  useChangePasswordMutation,
  useContactMutation,
  useFetchResidencesQuery,
  useFetchNeighbourhoodsQuery,
  useFetchStateCitiesQuery,
  useLazyFetchStateCitiesQuery,
  useVerifyEmailMutation,
  useFetchPlansQuery,
  useCreateCheckoutSessionMutation,
} = authApi;
