import React from "react";
import { Box, Container, Typography, Grid, Button } from "@mui/material";
import { Media } from "../../../config/media";
import { StyledBubbleBlock } from "./styles";
interface IBubbleCardProps {
  title: string;
  discription: string;
  className?: string;
  graphicimgurl: string;
  stepimgurl: string;
}

function BubbleCard({
  stepimgurl,
  discription,
  graphicimgurl,
  className,
  title,
}: IBubbleCardProps) {
  return (
    <StyledBubbleBlock sx={{ pt: {xs:8,md:25,lg:7} }} className={className}>
      <Box className="bubble-block">
        <Box
          component="img"
          alt="The shape"
          src={Media.BlueBubbleImage}
          className="img-style__contain bubble-img"
        />
        <Box className="bubble-text" sx={{ pt: 5 }}>
          <Box
            component="img"
            alt="The shape"
            src={stepimgurl}
            className="img-style__contain step-img"
          />
          <Box>
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body2" sx={{color:'primary.dark'}}>{discription}</Typography>
          </Box>
        </Box>

        <Box
          component="img"
          alt="The shape"
          src={graphicimgurl}
          className="img-style__contain img-graphic"
        />
      </Box>
    </StyledBubbleBlock>
  );
}

export default BubbleCard;
