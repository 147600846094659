import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledBannerContent = styled(Box)`
.banner-content{
    height: auto;
}
@media(min-width:600px){
    .banner-content{
        min-height: 67vh;
    }
}
`;