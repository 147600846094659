import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";


export const StyledBubbleBlock = styled(Box)`
.MuiTypography-body1{
  line-height: 1.4;
}
h3{
  margin-bottom: 0.8rem;
}
  .bubble-block {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 28.6em;
    height: 26em;
    z-index: 1;
    font-size: 16px;
  }

  &.petsgraphic-bottom .img-graphic {
    bottom: 0;
  }
  .bubble-text {
    display: flex;
    padding-left: 12px;
    left: 3.6em;
    position: relative;
    top: 1.5em;
    max-width: 59%;
  }
  .img-graphic {
    height: 9em;
    margin-top: auto;
    bottom: 2em;
    position: relative;
    right: 1.7em;
  }
  .step-img {
    margin-right: 8px;
    height: 4.6em;
    width: 3em;
    position: relative;
    top: 0.5em;
  }
  .bubble-img {
    position: absolute;
    inset: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
  &.right-one .bubble-block {
    margin-left: auto;
  }

  @media (min-width: 900px) {
    .bubble-block {
      margin-top: -12em;
      font-size: 18px;
    }
  }
  @media (min-width: 1200px) {
    .bubble-block {
      font-size: 20px;
    }
  }

  @media(max-width: 992px;){
    .img-graphic{
      height: 8em;
    }
    .bubble-block{
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  @media (max-width: 576px) {
    .step-img{
      height: 3.6em;
    width: 2em;
    }
    .bubble-text{
      max-width: 74%;
    }
    .bubble-block {
      font-size: 12px;
      height: 28em;
    }
    .bubble-block p {
      font-size: 12px;
      line-height: 1.5;
    }
    .bubble-block h3 {
      font-size: 14px;
      line-height: 1.5;
    }
  }
`;
