import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import { StyledPetDetailCard } from "./styles";
import LazyLoad, { forceCheck } from "react-lazyload";
import { Media } from "../../config/media";
import ShareIcons from "../ShareIcons";
interface IPetDetailCardProps {
  imgUrl?: string | null | undefined;
  date: string;
  time: string;
  message?: string | null | undefined;
  id: number | null | undefined;
}
function PetDetailCard({
  imgUrl,
  message,
  date,
  time,
  id,
}: IPetDetailCardProps) {

  return (
    <StyledPetDetailCard
      sx={{
        bgcolor: "secondary.contrastText",
      }}
    >
      <LazyLoad
        debounce={500}
        placeholder={
          <Skeleton
            variant="rectangular"
            animation="wave"
            height={382}
            sx={{
              mb: 2,
              width: "475px",
              "@media (max-width: 600px)": {
                width: "100%",
              },
            }}
          />
        }
      >
        <Box sx={{ position: "relative", mb: 1 }}>

          {imgUrl ? (
            <>
              <Box className="img-placeholder">
                <ShareIcons
                  ide={id}
                  imageUrl={imgUrl}
                  message={message}
                />
                <Box
                  component="img"
                  src={imgUrl}
                  className="img-style pet-img"
                />
              </Box>
            </>
          ) : (
            message?.toLowerCase() !== "no message" && (
              <Box className="img-style img-style__cover pet-img" sx={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <Box sx={{
                  background: 'white',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}>
                  <Box component="img" sx={{
                    borderTopRightRadius: '10px',
                    objectFit: 'contain',
                    borderTopLeftRadius: '10px',
                    width: '80%',
                  }} src={Media.NoMessage} alt="No Message Image" height="100%" />
                  <Box sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    pb: 2,
                    mt: '-20px',
                  }} component="span">SMS Message. No Image Sent.</Box>
                </Box>
              </Box>
            )
          )}
        </Box>
      </LazyLoad>
      {message && message.toLowerCase() !== 'no message' ? (
        <Box sx={{ pb: 1, px: 1 }}>
          <Box className="pet-text" sx={{ mb: 2 }}>
            <Typography component="span">{date}</Typography>
            <Typography component="span">{time}</Typography>
          </Box>
          <Typography
            className="pet-description"
            sx={{ color: "primary.dark" }}
          >
            {message || ""}
          </Typography>

        </Box>
      ) : (
        <Box sx={{ pb: 1, px: 1 }}>
          <Box className="pet-text" sx={{ mb: 2 }}>
            <Typography component="span">{date}</Typography>
            <Typography component="span">{time}</Typography>
          </Box>
        </Box>
      )}
    </StyledPetDetailCard>
  );
}

export default PetDetailCard;
