import React, { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';

interface DataItem {
  zip_code?: string;
  id: number;
  name: string | null | undefined;
  order?: number | null;
  timezone?: string | null;
}

type PropsType = {
  query: any;
  setFieldValue: any;
  fieldName: string;
  value: string | undefined;
  valueString: string | undefined;
  setTouched: any;
  touched?: boolean | undefined;
  timezone?: string | undefined;
  setValidZip?: any;
  setFieldError?: any;
};

const SearchableAutocomplete: React.FC<PropsType> = ({
  value,
  setFieldValue,
  fieldName,
  query,
  setTouched,
  valueString,
  timezone,
  setValidZip,

}) => {
  const [trigger, { data, isLoading }] = query();
  const [inputValue, setInputValue] = useState<string>('');
  const [defaultValue, setDefaultValue] = useState<DataItem | null>(null);
  const timeout = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [cityStateData, setCityStateData] = useState<DataItem[]>([]);

  useEffect(() => {
    if (data?.data && inputValue) {
      const fetchedData = data?.data
        ?.slice(0, 200)
        ?.filter(({ id }: any) => id !== 1)
        ?.map((item: any) => ({
          ...item,
          zip_code: item.zip_code
        }));
      setCityStateData([...fetchedData]);
    }
  }, [data, inputValue]);
  useEffect(() => {
    if (value || valueString || timezone) {
      const obj = {
        id: Number(value),
        name: valueString,
        timezone: timezone,
        order: null,
        zip_code: '',
      };
      setDefaultValue(obj);
      setCityStateData([obj]);
      setInputValue(valueString || '');
    }
  }, []);


  useEffect(() => {
    if (!inputValue) {
      setCityStateData([]);
      setFieldValue(fieldName, '');
      setFieldValue('other_state', '');
      return;
    }
    timeout.current = setTimeout(() => {
      setLoading(true);
      if (inputValue && inputValue !== defaultValue?.name) {
        try {
          trigger(inputValue);
        } catch (err: any) {
          // handle error
        }
      }
      setLoading(false);
    }, 100);
    return () => {
      if (timeout?.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [inputValue]);

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ) => {
    setInputValue(newInputValue);
    setFieldValue('zipCode', newInputValue); // Update the zip code field as the user types
  };
  const handleChange = (_event: any, newValue: any) => {
    if (newValue?.zip_code) {
      setInputValue(newValue.zip_code || '');
      setFieldValue('zipCode', newValue.zip_code);
      setFieldValue('city_state', newValue.id)
      setFieldValue('city_state_name', newValue.name);
      setValidZip(newValue?.zip_code)
      setDefaultValue(newValue);
    } else if (typeof newValue === 'string') {
      // Handle manual entry
      const matchedOption = cityStateData.find(option => option.zip_code === newValue);
      if (matchedOption) {
        setInputValue(matchedOption.zip_code || '');
        setFieldValue('zipCode', matchedOption.zip_code);
        setFieldValue('city_state', matchedOption.id)
        setFieldValue('city_state_name', matchedOption.name);
        setValidZip(matchedOption.zip_code);
        setDefaultValue(matchedOption);
      } else {
        setFieldValue('zipCode', newValue);
        setFieldValue('city_state', '1')
        setFieldValue('city_state_name', '');
        setDefaultValue(null);
      }
    } else {
      setFieldValue('zipCode', '');
      setFieldValue('city_state', '1')
      setFieldValue('city_state_name', '');
      setFieldValue(fieldName, newValue?.id || '');
      setDefaultValue(null);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const matchedOption = cityStateData.find(option => option.zip_code === inputValue);
      if (matchedOption) {
        handleChange(null, matchedOption);
      } else {
        handleChange(null, inputValue);
      }
    }
  };
  const showLoader = isLoading && loading;
  return (
    <Autocomplete
      fullWidth
      style={{ width: '100%' }}
      sx={{
        '.MuiInputBase-root': {
          padding: 0,
        },
      }}
      freeSolo
      options={cityStateData}
      renderOption={(props, option) => (
        <li {...props} key={option?.id}>
          <div>
            <span>{option?.zip_code ? option.zip_code : ''}</span>
          </div>
        </li>
      )}
      getOptionLabel={(option: any) => option.zip_code || option.name || ''}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      filterOptions={(options) => options}
      value={defaultValue}
      onChange={handleChange}
      loading={showLoader}
      onKeyDown={handleKeyDown}
      onBlur={() => setTouched(fieldName, true)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Zip Code"
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
    />
  );
};

export default SearchableAutocomplete;
