import React, { useEffect, useState } from 'react';
import { StyledPricingBlock } from './styles';
import { Box, CircularProgress, Container, Grid, Tab, Tabs, Typography } from '@mui/material';
import SubscriptionCard from './SubscriptionCard';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useFetchPlansQuery } from '../../redux/user/userApi';
import Loader from '../Loader';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

function CustomTabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function SubscriptionPlans() {
  const [value, setValue] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const { data: plansData, isFetching } = useFetchPlansQuery({});
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Elements stripe={stripePromise}>
      <StyledPricingBlock component="section">
        <Box className="sec-spacing pricing-sec">
          <Box
            sx={{ display: { xs: 'block', sm: 'none' } }}
            component="img"
            alt="The shape"
            src="/assets/images/shape-blue.svg"
            className="img-style__contain blue-bubble"
          />
         { isFetching ? (
           <Loader/>
          ) : (
            <Box textAlign="center" sx={{ ml: 'auto', mr: 'auto' }}>
            <Box className="priceContent">
              <Typography variant="h2" mb={2}>
                Choose Your Plan
              </Typography>
              <Typography
                variant="body1"
                mb={3}
                sx={{
                  maxWidth: { xs: '250px', sm: '100%' },
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  color: 'info.darker',
                }}
              >
                Subscribe to our curated plans and avail extra features!
              </Typography>
              <Box className="card-block" sx={{ mb: 4, color: 'info.darker' }}>
                <Typography
                  variant="body2"
                  mb={3}
                  sx={{
                    color: 'info.darker',
                  }}
                >
                  The{' '}
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'inline',
                      color: 'primary.dark',
                      fontWeight: '700',
                    }}
                  >
                    Starter Plan
                  </Typography>{' '}
                  is a great way to try out MyPetsText for a while on a budget.
                </Typography>
                <Typography
                  variant="body2"
                  mb={3}
                  sx={{
                    color: 'info.darker',
                  }}
                >
                  The{' '}
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'inline',
                      color: 'primary.dark',
                      fontWeight: '700',
                    }}
                  >
                    Basic Plan
                  </Typography>{' '}
                  features a combination of customized text and image MyPetsText
                  messages every day of the month, or multiple times a day
                  periodically during the week.
                </Typography>
                <Typography
                  variant="body2"
                  mb={3}
                  sx={{
                    color: 'info.darker',
                  }}
                >
                  The{' '}
                  <Typography
                    variant="body2"
                    sx={{
                      display: 'inline',
                      color: 'primary.dark',
                      fontWeight: '700',
                    }}
                  >
                    Family Plan
                  </Typography>{' '}
                  includes customized text messages and images for the whole
                  family. Subscribers in the same account will receive the same
                  messages to their phones every day, multiplying the fun.
                </Typography>
              </Box>

              <Typography
                variant="body1"
                mb={2}
                sx={{
                  fontStyle: 'italic',
                  color: 'info.darker',
                }}
              >
                MyPetsText uses a credit-based system for each subscription. Use
                our handy credit calculator to decide how often and which kind
                of messages you want to receive.
              </Typography>
              <Typography
                variant="body1"
                mb={3}
                sx={{
                  color: 'info.darker',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <Typography sx={{ display: 'inline', color: 'info.darker' }}>
                  {' '}
                  Text = 1 credit{' '}
                </Typography>
                <Typography sx={{ display: 'inline', color: 'info.darker' }}>
                  {' '}
                  Image = 2 credits{' '}
                </Typography>
                <Typography sx={{ display: 'inline', color: 'info.darker' }}>
                  {' '}
                  Text & Image = 3 credits{' '}
                </Typography>
              </Typography>
            </Box>
            
            <Box sx={{ width: '100%' }}>
              <Box mb={2}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="pricing-sec__tabslist"
                >
                  <Tab
                    label={
                      <Typography variant="body2">
                        Annually
                        <Typography variant="body2" className="badge">
                          -10% to -30%
                        </Typography>
                      </Typography>
                    }
                    {...a11yProps(0)}
                  />
                  <Tab label="Monthly" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0} className="tab-content">
                <Grid container spacing={3} justifyContent="center">
                  {plansData && plansData['annually'].map((product, index) => (
                    <Grid item xs={12} md={6} lg={3} key={index}>
                      <SubscriptionCard
                        data={product}
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                        value={value}
                        planCategory='annually'
                      />
                    </Grid>
                  ))}
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1} className="tab-content">
                <Grid container spacing={3} justifyContent="center">
                  {plansData && plansData['monthly'].map((product, index) => (
                    <Grid item xs={12} md={6} lg={3} key={index}>
                      <SubscriptionCard
                        data={product}
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                        value={value}
                        planCategory='monthly'
                      />
                    </Grid>
                  ))}
                </Grid>
              </CustomTabPanel>
            </Box>
          </Box>
          )}
        
        </Box>
      </StyledPricingBlock>
    </Elements>
  );
}

export default SubscriptionPlans;
