import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
} from '@mui/material';
import { Media } from '../../../config/media';
import { StyledPricingCard } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { useNavigate } from 'react-router-dom';
import { GlobalRoutes } from '../../../types';
import { useStripe } from '@stripe/react-stripe-js';
import { useCreateCheckoutSessionMutation } from '../../../redux/user/userApi';
import { PricingItemsType } from '../../../utils/plan';
type SubscriptionCardProps = {
  data: PricingItemsType;
  selectedPlan: string;
  setSelectedPlan: any;
  value: number;
  planCategory: 'monthly' | 'annually';
};

function SubscriptionCard({
  data: {
    title,
    price,
    priceSubtitle,
    features,
    creditsText,
    description,
    priceId,
  },
  selectedPlan,
  setSelectedPlan,
  value,
  planCategory = 'monthly',
}: SubscriptionCardProps) {
  const { userData }: any = useSelector(
    (state: RootState) => state.user
  );
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const isSubscribed = () => {
    return userData && 
           userData.subscription_plan_detail !== null && 
           userData.subscription_plan_detail !== undefined && 
           Object.keys(userData.subscription_plan_detail).length > 1;
  }
  const navigate = useNavigate();
  const stripe = useStripe();
  const calculatedprice = price;
  let priceFixed: any = price;
  if (planCategory === 'annually') {
    priceFixed = (+price / 12).toFixed(2);
  }
  const [createCheckoutSession] = useCreateCheckoutSessionMutation();
  const handleNavigation = () => {
    navigate(GlobalRoutes.REGISTER);
  };

  if (isSubscribed() && isAuthenticated) {
    setSelectedPlan(()=> userData &&  userData.subscription_plan_detail ?  userData.subscription_plan_detail.stripe_price_id : '')
  }
  const handleBuyNow = async () => {
    if (!stripe || !priceId) return;
    try {
      const res = await createCheckoutSession({ priceId: priceId });
      if ('data' in res) {
        if (res?.data) {
          const { data: session }: any = res.data;
          window.open(session.url, '_self');
        }
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  return (
    <StyledPricingCard>
      <Box
        className={`price-block card-block ${
          selectedPlan === priceId ? 'selected' : ''
        }`}
        sx={{
          backgroundColor: 'primary.contrastText',
          alignItems: 'start',
          cursor: 'pointer',
        }}
        onClick={() => setSelectedPlan(priceId)}
      >
        <Typography variant="h2" mb={5}>
          {title}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            ml: 0,
            mb: 1,
            fontWeight: '700',
            color: 'secondary.main',
            lineHeight: '1',
            fontSize: {
              xs: '3rem',
              sm: '4rem',
              md: '4rem',
              xl: '4rem',
            },
          }}
        >
          ${priceFixed}
          {value === 1 || Number(priceFixed) === 0.0 ? (
            ''
          ) : (
            <Box
              component="span"
              sx={{
                color: 'orange',
                fontSize: '1rem',
                ml: 1,
                width: '15px',
                display: 'inline-block',
              }}
            >
              ${calculatedprice} annually
            </Box>
          )}
        </Typography>

        <Typography variant="body2" mb={6} className="price-block__sub">
          {priceSubtitle}
        </Typography>
        {features?.length && (
          <List className="price-block__list">
            {features.map((feat: string) => (
              <ListItem>
                <ListItemIcon>
                  <Box
                    component="img"
                    alt="The shape"
                    src={Media.TickIcon}
                    className="img-style__contain "
                  />
                </ListItemIcon>
                <ListItemText>{feat}</ListItemText>
              </ListItem>
            ))}
          </List>
        )}
        <Box textAlign="center" sx={{ marginTop: 'auto', width: '100%' }}>
          {!isAuthenticated ? (
            <Button
              variant="contained"
              color="secondary"
              className="subscribe"
              sx={{ mt: { xs: 7, sm: 8, md: 9, lg: 10 } }}
              onClick={handleNavigation}
            >
              Sign Up Now
            </Button>
          ) : !isSubscribed() ? (
            <Button
              variant="contained"
              color="secondary"
              className="subscribe"
              sx={{ mt: { xs: 7, sm: 8, md: 9, lg: 10 } }}
              onClick={handleBuyNow}
            >
              Buy Now
            </Button>
          ) : null}
        </Box>
      </Box>
    </StyledPricingCard>
  );
}

export default SubscriptionCard;
