import React from "react";
import { StyledVideoBlock } from "./styles";
import { Box, Button, Container, Typography } from "@mui/material";
import TextEdit from "../TextEdit";
import { useContent } from "../../hooks/useContent";
import { GlobalRoutes } from "../../types";
import { useNavigate } from "react-router-dom";

function VideoBanner() {
  const _html = useContent('home', 'hero');  
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(GlobalRoutes.PRICING);
  }

  return (
    <StyledVideoBlock component="section" className="banner">
      <Box
        className="banner-wrap"
        component="video"
        src="https://s3-mypetstext.s3.amazonaws.com/homepage/dummy-video+Compressed.mp4"
        muted
        loop
        autoPlay={true}
      />
      <Container fixed maxWidth="xl">
        <Box
          className="banner-content"
          sx={{ color: "primary.contrastText", py: 5 }}
        >
          <TextEdit page="home" sectionName="hero" _html={_html} >
          <Box>
            <Typography
              variant="h1"
              sx={{
                mb: 2,
                fontSize: { xs: 30, sm: 34, md: 36, lg: 38 },
              }}
            >
              Strengthen Your Bond with Daily Texts from Your Pet!
            </Typography>
            <Typography
              variant="h3"
              sx={{
                mb: { xs: 4, sm: 5, md: 6, lg: 7 },
              }}
            >
              Fun, personalized messages and images that bring joy and laughter.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "primary.contrastText",
                mb: { xs: 3, sm: 4, md: 5, lg: 6 },
              }}
            >
              Your pet’s love, now in daily texts! Join our alpha testing and
              start receiving heartwarming messages from your furry friend.
            </Typography>
          </Box>
          </TextEdit>
          <Box>
          <Button variant="contained" color="secondary" sx={{mx:2}} onClick={handleClick}>
            Get Started Today
          </Button>
          </Box>

        </Box>
      </Container>
    </StyledVideoBlock>
  );
}

export default VideoBanner;
