import { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Checkbox,
  TextField,
  Grid,
  Button,
  Pagination,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import {
  useAddUserActionMutation,
  useFetchAllPetsQuery,
  useFetchPetByIdQuery,
  useFetchPublicGalleryDataQuery,
  useLazyFetchPetGalleryDataQuery,
} from "../../redux/pet/petApi";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PublicPetDetailCard from "../../components/PublicPetDetailCard";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useToast } from "../../hooks/useToast";
import { format, parseISO } from "date-fns";
import Loader from "../../components/Loader";
import { useLocation } from "react-router-dom";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type PetGalleryProps = {};

type PetData = {
  id: number;
  name: string;
};

type PaginationType = {
  page: number;
  page_size: number;
}

const PetGallery: React.FC<PetGalleryProps> = () => {
  const { data } = useFetchAllPetsQuery({});
  // State for pets data
  const [pets, setPets] = useState<PetData[]>([]);
  const [selected, setSelected] = useState<PetData[]>([]);
  const [searchQ, setSearchQ] = useState<string>("");
  let timeoutId = useRef<any>(null);
  const toast = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  
  const [triggerUserAction] = useAddUserActionMutation();
  const location = useLocation();
  const petId = Number(location?.state?.petId) || 0;
  const [commentSend, setCommentSend] = useState<boolean>(false);
  const [commentId, setCommentId] = useState<number>(0);
  const [pagination, setPagination] = useState<PaginationType>({
    page: 1, 
    page_size: 12, 
  });
  const { data: galleryData, isLoading, refetch } = useFetchPublicGalleryDataQuery({
    searchQ,
    ...pagination,
  });
  
  const [totalPages, setTotalPages] = useState<number>(1);
  // Initialize the selected data
  useEffect(() => {
    if (data && !petId) {
      const updatedData = data?.data?.map(({ id, name }: any) => ({
        id,
        name,
      }));
      if (updatedData?.length) {
        setPets(updatedData);
        setSelected(updatedData);
      }
    } else {
      if (petId) {
        const pet = data?.data?.find(({ id }: any) => id === petId);
        if (pet) {
          setSelected([{ id: pet?.id, name: pet?.name }]);
        }
        const updatedData = data?.data?.map(({ id, name }: any) => ({
          id,
          name,
        }));
        if (updatedData?.length) {
          setPets(updatedData);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (galleryData?.count) {
      const totalPages = Math.ceil(galleryData.count / pagination.page_size);
      setTotalPages(totalPages);
    }
  }, [galleryData, pagination.page_size]);
  useEffect(() => {
    searchGalleryData();
  }, [pagination]);

  // Effect to search gallery data, when search query or pets updates
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setPagination((prev) => ({ ...prev, page: 1 }));
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [selected, searchQ]);

  const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQ(e.target.value);
  };

  async function searchGalleryData() {
    try {
      setLoading(true);
    await refetch();
    scrollTop();
      setLoading(false);
    } catch (err: any) {
      toast({
        title: "Error searching pet data",
        severity: "error",
      });
      setLoading(false);
    }
  }


  const handleUserAction = async (payload: any) => {
    try {
      await triggerUserAction(payload);
    } catch (err: any) {
      // handle error
    }
  };

  const handleLikeDislike = async (value: {
    user_prompt_id: number;
    action: string;
    reaction: number;
  }) => {
    await handleUserAction({
      ...value,
    });
  };

  const handleComment = async (value: {
    user_prompt_id: number;
    action: string;
    reaction: number;
    userText: string;
  }) => {
    try {
      await handleUserAction({
        ...value,
      });
      setCommentSend(true);
      setCommentId(value.user_prompt_id);
      setTimeout(() => {
        setCommentSend(false);
      }, 4000);
    } catch (err: any) {
      //handle err
    }
  };

    const handleChange = (_e: any, values: any) => {
    if (values?.length === 0) {
      if (pets.length) {
        setSelected(pets);
      }
    } else {
      setSelected(values);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth', 
    });
  }

  const handlePagination = (e: any, page: number) => {
    setPagination({
      ...pagination,
      page: page, 
    });
    scrollTop();
  }

  return (
    <Box sx={{ mt: 5, minHeight: "calc(100% - 440px)" }} component="section">
      <Container fixed maxWidth="xl">
        <Typography variant="h1" textAlign="center" mt={15} mb={2}>
          Pet’s Gallery
        </Typography>
        <Box
          className="search-field"
          sx={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Button sx={{ p: 0, color: "info.darker" }}>
            {" "}
            <SearchIcon />
          </Button>
          <TextField
            fullWidth
            id="input-with-sx"
            variant="outlined"
            placeholder="Search"
            value={searchQ}
            onChange={handleSearchQuery}
            InputProps={{
              endAdornment: searchQ ? <ClearIcon sx={{ cursor: 'pointer' }} onClick={() => setSearchQ('')} /> : null
            }}
          />
        </Box>
        <Grid container spacing={4} sx={{ pb: 5 }}>
          {galleryData?.results && galleryData?.results?.length
            ? galleryData.results.map(
                ({
                  reactions,
                  id,
                  output_image,
                  message,
                  date_time,
                  pet_id,
                }: any) => (
                  <Grid key={id} item xs={12} md={6} lg={4} sx={{overflow:'hidden'}}>
                    
                    <PublicPetDetailCard
                      imgUrl={output_image}
                      id={id}
                      date={format(parseISO(date_time), "MMMM d, yyyy")}
                      time={format(parseISO(date_time), "HH:mm")}
                      message={message}
                    />
                  </Grid>
                )
              )
            : loading === false && (
                <Box sx={{ textAlign: "center", width: "100%", my: 5 }}>
                  <Typography variant="h1">No Data Found!</Typography>
                </Box>
              )}
        </Grid>
        {galleryData?.results.length ? <Box sx={{
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center',
          pb: 5
        }}>
          <Pagination count={totalPages} onChange={handlePagination} variant="outlined" shape="rounded" color="primary" size="large" />
        </Box> : null}
      </Container>
      {loading && <Loader />}
    </Box>
  );
};

export default PetGallery;
