import React, { useState } from "react";
import { Box, Container, Grid, Typography, TextField, Button } from "@mui/material";
import SideBanner from "../../../components/auth/SideBanner";
import { Message } from "../../../config/messages";
import RegisterForm from "../../../components/auth/RegisterForm/layout";
import SideImgBanner from "../../../components/SideImgBanner";

function Register() {
  const [couponState, setCouponState] = useState<boolean>(true);
  const [couponCode, setCouponCode] = useState<string>("");

  const handleCouponSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Coupon submitted:", couponCode);
    setCouponState(true);
  };

  return (
    <Box className="main">
      <Container fixed maxWidth="xl">
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            className="login-info"
            sx={{ position: { xs: "relative", sm: "static" }, minHeight: { lg: '900px' }, display: 'flex' }}
          >
            <SideImgBanner />
            <SideBanner
              title={Message.LoginTitle}
              content={Message.RegisterContent}
            />
          </Grid>
          {couponState ? (
            <Grid item xs={12} md={6}>
              <Box
                className="login-wrap"
                sx={{
                  paddingLeft: { md: "4rem" },
                  paddingTop: { xs: "2rem", sm: "6rem", lg: "7rem" },
                  paddingBottom: { xs: "2rem", md: "7rem" },
                }}
              >
                <Typography variant="h1" sx={{ mb: 2 }}>
                  {Message.Register}
                </Typography>
                <RegisterForm title={Message.Register} />
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Box
                className="login-wrap"
                sx={{
                  paddingLeft: { md: "4rem" },
                  paddingTop: { xs: "2rem", sm: "6rem", lg: "7rem" },
                  paddingBottom: { xs: "2rem", md: "7rem" },
                }}
              >
                <Typography variant="h1" sx={{ mb: 2 }}>
                  {Message.CouponTitle}
                </Typography>
                <form onSubmit={handleCouponSubmit}>
                  <TextField
                    fullWidth
                    label="Enter your coupon code"
                    variant="outlined"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                    sx={{ mb: 2 }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Submit Coupon
                  </Button>
                </form>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}

export default Register;