import { Navigate } from 'react-router-dom';
import MyAccount from '../pages/my-account/layout';
import { GlobalRoutes } from '../types';
import Profile from '../pages/my-account/Profile/layout';
import PetList from '../pages/my-account/PetList/layout';
import ScheduleMessage from '../pages/my-account/ScheduleMessage/layout';
import MyCredits from '../pages/my-account/MyCredits/layout';
import EditProfile from '../pages/my-account/EditProfile/layout';
import ChangePassword from '../pages/auth/ChangePassword';
import AuthProvider from '../providers/AuthProvider';
import MyPets from '../pages/MyPets/layout';
import Mygallery from "../pages/MyGallery/layout"
import PetDetail from '../pages/PetDetail/layout';
import EditPet from '../pages/my-account/EditPet/layout';
import AddPet from '../pages/my-account/AddPet/layout';

export const protectedRoutes = [
  {
    path: GlobalRoutes.CHANGE_PASSWORD,
    element: (
      <AuthProvider>
        <ChangePassword />
      </AuthProvider>
    ),
  },
  {
    path: GlobalRoutes.PET_DETAIL,
    element: <PetDetail />,
    index: true,
  },
  {
    path: GlobalRoutes.MY_PETS,
    element: (
      <AuthProvider>
        <MyPets />
      </AuthProvider>
    ),
  },
  {
    path: GlobalRoutes.MY_GALLERY,
    element: (
      <AuthProvider>
        <Mygallery />
      </AuthProvider>
    ),
  },
  {
    path: GlobalRoutes.MY_ACCOUNT,
    element: (
      <AuthProvider>
        <MyAccount />
      </AuthProvider>
    ),
    children: [
      {
        element: <Navigate to={GlobalRoutes.ACCOUNT_PROFILE} />,
        index: true,
      },
      {
        path: GlobalRoutes.ACCOUNT_PROFILE,
        element: <Profile />,
      },
      {
        path: GlobalRoutes.ACCOUNT_MY_PETS,
        element: <PetList />,
      },
      {
        path: GlobalRoutes.ACCCOUNT_SCHEDULE_MESSAGE,
        element: <ScheduleMessage />,
      },
      {
        path: GlobalRoutes.ACCOUNT_CREDITS,
        element: <MyCredits />,
      },
      {
        path: GlobalRoutes.EDIT_PROFILE,
        element: <EditProfile />,
      },
      {
        path: GlobalRoutes.ADD_PET,
        element: <AddPet />,
      },
      {
        path: GlobalRoutes.EDIT_PET,
        element: <EditPet />,
      },
      {
        path: '*',
        element: <Navigate to={GlobalRoutes.ACCOUNT_PROFILE} replace />,
      },
    ],
  },
];
