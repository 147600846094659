import React, { useState } from "react";
import {
  Alert,
  Box,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useFormik, FormikProps } from "formik";
import {
  changePasswordSchema,
} from "../../../validators/schemas/authSchemas";
import {
  IApiResponse,
  IChangePassword,
} from "../../../interfaces/auth";
import { useChangePasswordMutation } from "../../../redux/user/userApi";
import { Message } from "../../../config/messages";
import { useToast } from "../../../hooks/useToast";

interface ChangePasswordFormProps {
  title: string;
}

function ChangePasswordForm({ title }: ChangePasswordFormProps): JSX.Element {
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<Boolean>(false);
  const [error, setError] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const toast = useToast();

  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
  }: FormikProps<any> = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: async (
      { password, confirm_password },
      { resetForm }
    ) => {
      if (confirm_password !== password) return;
      try {
        const payload: IChangePassword = {
          confirm_password,
          password,
        };
        console.log(payload)
        await changePassword(payload)
          .unwrap()
          ?.then((res: IApiResponse) => {

                resetForm();
                setShowAlert(false);
                toast({
                  title: res?.message || Message.SuccessfullyCreated,  
                  severity: 'success', 
                });
                setError('');
              
          })
          ?.catch((error: any) => {            
            setError(error?.data.errors.error);
            setShowAlert(true)

          });
      } catch (err: any) {
        setError(Message.SomethingWrong);
      }
    },
  });
 const { password, confirm_password } = values;

  return (
    <>
      <Typography variant="h1" my={2} sx={{ position: "relative" }}>
        {title}
      </Typography>

      <Box>
        {(error && showAlert) && (
          <Collapse in>
            <Alert
              sx={{ my: 2 }}
              variant="filled"
              severity="error"
              onClose={() => setShowAlert(false)}
            >
              {error}
            </Alert>
          </Collapse>
        )}
        <form onSubmit={handleSubmit}>
          <FormControl sx={{ my: 1.5, width: "100%" }} variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              name="password"
              value={password}
              onChange={handleChange}
              onBlur={handleBlur}
              color={errors.password ? "error" : "primary"}
              error={!!(touched.password && errors.password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
            {errors.password && (
              <FormHelperText error id="accountId-error">
                {touched.password &&
                  errors.password &&
                  typeof errors.password === "string" &&
                  errors.password}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl sx={{ my: 1.5, width: "100%" }} variant="outlined">
            <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
            <OutlinedInput
              id="confirmPassword"
              name="confirm_password"
              value={confirm_password}
              type={showConfirmPassword ? "text" : "password"}
              onChange={handleChange}
              onBlur={handleBlur}
              color={errors.email ? "error" : "primary"}
              error={!!(touched.confirm_password && errors.confirm_password)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
            />
            {errors.confirm_password && (
              <FormHelperText error id="accountId-error">
                {touched.confirm_password &&
                  errors.confirm_password &&
                  typeof errors.confirm_password === "string" &&
                  errors.confirm_password}
              </FormHelperText>
            )}
          </FormControl>

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isLoading}
            sx={{ mt: 3, mb: 6 }}
          >
            {title}
          </LoadingButton>
        </form>
      </Box>
    </>
  );
}

export default ChangePasswordForm;
