import dayjs from 'dayjs';
import * as yup from 'yup';

export const userDetailsSchema = yup.object().shape({
  fullName: yup.string().required('Full Name is required'),
  mobileNumber: yup.string(),
  gender: yup
    .mixed()
    .oneOf(['male', 'female', 'others'])
    .required('Gender is required'),
  dob: yup.date().required('Date Of Birth is required'),
  residenceType: yup.string(),
  neighbourhoodType: yup.string(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string()
    .required('Zip code is required')
    .matches(/^[0-9]{5}$/, 'Invalid zip code format'),
  city_state_name: yup.string().required('City and State are required'),
});

export const editUserSchema = yup.object().shape({
  profilePic: yup.mixed(),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  contactNumber: yup
    .string()
    .min(10, 'Invalid Contact Number')
    .max(14, 'Invalid Contact Number')
    .required('Contact Number is required')
    .matches(/^\(\d{3}\) \d{3}-\d{4}$/, 'Valid US phone numbers supported at this time'),
  gender: yup.mixed().oneOf(['M', 'F', 'O']).required('Gender is required'),
  dateOfBirth: yup
    .string()
    .nullable()
    .required('Date of Birth is required')
    .test('is-valid-date', 'Invalid date', (value) => {
      return !value || dayjs(value, 'YYYY-MM-DD', true).isValid();
    }),
  email: yup.string().email().required('Email is required'),
  typeOfResidence: yup.string().required('Type of Residence is required'),
  typeOfNeighborhood: yup
    .string()
    .required('Type of Neighborhood is required'),
  address: yup.string().required('Address is required'),
  city_state_name: yup.string().required('City and State is required'),
  other_state: yup.string().when('city', (city, schema) => {
    return Number(city) === 1
      ? schema.required('City and State is required')
      : schema;
  }),
  zipCode: yup
    .string()
    .max(5, 'Zip Code cannot be longer than 5 character')
    .min(5, 'Zip Code cannot be shorter than 5 character')
    .required('Zip Code is required'),
  subscribed: yup
    .boolean()
    .oneOf(
      [true],
      'You must agree to receive SMS/MMS messages from MyPetsText'
    ),
});

export const giftingDetailsSchema = yup.object().shape({
  fullName: yup.string().required('Full Name is required'),
  mobileNumber: yup.string(),
  gender: yup
    .mixed()
    .oneOf(['male', 'female', 'others'])
    .required('Gender is required'),
  petName: yup.string().required('Pet Name is required'),
  plan: yup.string(),
});
