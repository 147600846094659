import { IForgotPassword, ILogin, ILoginResponseInterface, IRegister, IRegisterResponse, IResetPassword, IResetPasswordResponse, IResponseInterface } from "../../interfaces/auth";
import { IGoogleAccessToken, IGoogleLogin, IGoogleUserType } from "../../interfaces/social";
import { appApi } from "../apis/apiSlice";

const authApiWithTag = appApi.enhanceEndpoints({
    addTagTypes: ["Auth", "USERS"],
});

export const authApi = authApiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        loginUser: builder.mutation<ILoginResponseInterface, ILogin>({
            query: ({ email, password }) => {
                return {
                    url: "/user/login",
                    method: "POST",
                    body: {
                        email,
                        password,
                    },
                };
            },
             invalidatesTags: ["USERS"],
         
        }),
        registerUser: builder.mutation<IRegisterResponse, IRegister>({
            query: (body) => {
                return {
                    url: "/user/register",
                    method: "POST",
                    body
                };
            },
        }),

        forgotPassword: builder.mutation<IResponseInterface, IForgotPassword>({
            query: ({ email }) => {
                return {
                    url: "/user/forget-password",
                    method: "POST",
                    body: {
                        email,
                    },
                };
            },
        }),

        resetpassword: builder.mutation<IResetPasswordResponse, IResetPassword>({
            query: ({ token, uidb64, password }) => {
                return {
                    url: "/user/reset-password",
                    method: "POST",
                    body: {
                        token,
                        uidb64,
                        password,
                    },
                };
            },
        }),
        googleAccessToken: builder.mutation<IGoogleUserType, IGoogleAccessToken>({
            query: ({ access_token, scope }) => {
                return {
                    url: `'${process.env.REACT_APP_GOOGLE_API_URL}'`,
                    method: "POST",
                    body: {
                        access_token,
                        scope,
                    },
                };
            },
        }),
        socialLogin: builder.mutation<ILoginResponseInterface, IGoogleLogin>({
            query: ({ email, first_name, last_name, image }) => {
                return {
                    url: '/user/social-auth-token',
                    method: "POST",
                    body: {
                        email,
                        first_name, 
                        last_name, 
                        image: image || '', 
                    },
                };
            },
            invalidatesTags: ["USERS"],
        }),
        
        verifyEmailsync: builder.mutation<any, any>({
            query: ({ uidb64, token }) => {
                return {
                    url: `/user/activate`,
                    method: "POST",
                    body: {
                        uidb64, 
                        token, 
                    }
                };
            },
            transformErrorResponse: (error: any) => {
                return error
            },
        }),
    }),
});

export const {
    useLoginUserMutation,
    useRegisterUserMutation,
    useForgotPasswordMutation,
    useResetpasswordMutation,
    useSocialLoginMutation, 
    useVerifyEmailsyncMutation,
    useGoogleAccessTokenMutation
} = authApi;
