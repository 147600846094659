export enum Message{
    LoginTitle          = 'Log in or Sign up! It\'s free!',
    LoginContent        = 'With your free account, you will be able to generate a few pet-related quotes every month, and you can share these via the showcase completely free of charge!',
    Login               = 'Login',
    Register            = 'Sign Up',
    CouponTitle         = 'Coupon Code',
    RegisterContent     = 'Your Privacy is our Priority',
    ForgotPassword      = 'Forgot Password',
    ForgotPasswordContent = 'Please enter your email address. You will receive a link to your email to create a new password.',
    PasswordReset       = 'Reset Password',
    EmailAlreadyTaken   = 'This Email has already been taken',
    NotMatched         = 'Password do not match',
    InvalidText        = 'Confirm Password is required',
    LoginSuccessfully  = 'User logged in successfully',
    SomethingWrong     = 'Something went wrong',
    NotVerifiedEmail   = 'Email Not Verified.',
    LogoutSuccessfully = 'User logged out successfully',
    SuccessfullyCreated = 'Password successfully changed', 
    ActivateAccount = 'Please check your email for account activation', 
    EmailExists = 'User with this email already exists', 
}