import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { StyledBannerContent } from './styles';

interface ImgBannerContentProps {
  img: string;
  title: string;
}

function ImageBannerContent({ img, title }: ImgBannerContentProps) {
  return (
    <StyledBannerContent component="section" className="banner">
      <Box
        component="img"
        src={img}
        className="img-style img-style__cover banner-wrap"
      />
      <Container fixed maxWidth="xl">
        <Box
          className="banner-content"
          sx={{ color: 'primary.contrastText', py: 5 }}
        >
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h1"
              sx={{
                mb: 2,
                fontSize: { xs: 30, sm: 34, md: 36, lg: 38 },
              }}
            >
              {title}
            </Typography>
          </Box>

          <Button variant="contained" color="secondary">
            Try It Free
          </Button>
        </Box>
      </Container>
    </StyledBannerContent>
  );
}

export default ImageBannerContent;
