import { GlobalRoutes } from "../types";
import { Stepper } from "./constants";

type NavItem = {
    title: string;
    path: string;
    isProtected?: boolean;
    stepperCount?: number
}
type NavItemsType = NavItem[];

export const navItems: NavItemsType = [
    {
        title: 'Home', 
        path: `${GlobalRoutes.HOME}`, 

    }, 
    {
        title: 'How it Works', 
        path: `${GlobalRoutes.HOWWORKS}` //'/how-works', 
      
    }, 
    {
        title: 'Features',
        path: `${GlobalRoutes.FEATURES}` // '/features',
    },
   
    {
        title: 'Pricing', 
        path: `${GlobalRoutes.PRICING}` // '/Pricing'
    },
    {
        title: 'FAQS', 
        path: `${GlobalRoutes.FAQ}` // '/faqs', 
    }, 
    {
        title: 'About Us', 
        path: `${GlobalRoutes.ABOUT}` // '/about-us', 
    }, 
    {
        title: 'Contact', 
        path: `${GlobalRoutes.CONTACT}` //'/contact-us', 
    }, 
    {
        title: 'Gallery', 
        path: `${GlobalRoutes.PET_GALLERY}`, // '/pet-gallery', 
        stepperCount: Stepper.SCHEDULE_MESSAGE, 
    },
    {
        title: 'My Pets', 
        path: `${GlobalRoutes.MY_PETS}`, // '/my-pets', 
        isProtected: true, 
        stepperCount: Stepper.MY_PETS, 
    }
]