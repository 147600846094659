import React, { useEffect, useState } from "react";
import { Box, Button, Container, Link as MuiLink, Typography } from "@mui/material";
import { Media } from "../../config/media";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StyledPetEditDetails } from "./styles";
import {
  useFetchPetByIdQuery,
  useLazyFetchPetBreedsByTypeQuery,
  useLazyFetchPetColorsByTypeQuery,
  useLazyFetchPetMarkingByTypeQuery,
} from "../../redux/pet/petApi";
import { useToast } from "../../hooks/useToast";
import { Link, useNavigate } from "react-router-dom";
import { GlobalRoutes } from "../../types";
import { petTypeById } from "../../config/constants";

type PropTypes = {
  petId?: string | undefined;
};

function PetEditDetails({ petId }: PropTypes) {
  const { data, isError } = useFetchPetByIdQuery(petId ? +petId : 0);
  const [fetchPetBreeds, { data: breeds }] = useLazyFetchPetBreedsByTypeQuery();
  const [fetchPetColors, { data: colors }] = useLazyFetchPetColorsByTypeQuery();
  const [fetchPetMarking, { data: markings }] =
    useLazyFetchPetMarkingByTypeQuery();
  const [marking, setMarking] = useState<string>("");
  const [color, setColor] = useState<string>();
  const [breed, setBreed] = useState<string>("");

  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      fetchPetBreeds(data?.pet_type_id);
      fetchPetColors(data?.pet_type_id);
      fetchPetMarking(data?.pet_type_id);
    }
  }, [data]);

  useEffect(() => {
    if (isError) {
      toast({
        title: "Pet not found, Or may be pet is removed",
        severity: "error",
      });
      handleBack();
    }
  }, [isError]);

  // Initialize marking, color, breed,
  useEffect(() => {
    let markingStr: string = "";
    let breedStr: string = "";
    let colorStr: string = "";
    if (markings) {
      markings?.data.forEach((m: any) => {
        if (data?.marking_ids.split(",").includes(String(m.id))) {
          markingStr += `${m.name}, `;
        }
      });
      markingStr = markingStr.trim();
    }
    if (colors) {
      colors?.data.forEach((c: any) => {
        if (data?.color_ids.split(",").includes(String(c.id))) {
          colorStr += `${c.name}, `;
        }
      });
      colorStr = colorStr.trim();
    }
    if (breeds) {
      breeds?.data.forEach((b: any) => {
        if (data?.primary_breed_id === b.id) {
          breedStr += `${b.name}`;
        }
      });
      breedStr = breedStr.trim();
    }
    setMarking(markingStr.endsWith(',') ? markingStr.slice(0, -1) : markingStr);
    setBreed(breedStr.endsWith(',') ? breedStr.slice(0, -1): breedStr);
    setColor(colorStr.endsWith(',') ? colorStr.slice(0, -1): colorStr);
  }, [markings, breeds, colors]);

  const handleBack = () => {
    navigate(GlobalRoutes.MY_PETS);
  };

  const handleEditNavigation = () => {
    navigate(`/my-account/edit-pet/${petId}`, {
      state: {
        path: `/my-pets/detail/${petId}`,  
      }
    });
  };

  const navigateToGallery = () => {
    navigate(GlobalRoutes.MY_GALLERY, {
      state: { petId: petId },
    });
  };
  return (
    <StyledPetEditDetails
      sx={{
        bgcolor: "secondary.light",
        paddingBottom: { xs: "2.5rem", md: "5.5rem" },
        pt: 7,
      }}
    >
      <Container fixed maxWidth="xl">
        <Box sx={{ display: { md: "flex" }, justifyContent: "space-between" }}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            <Link to="/my-pets">
            <MuiLink className="pet-link">
              <ArrowBackIcon />
              {data?.name} Details
            </MuiLink>
            </Link>
          </Typography>

          <Button
            variant="contained"
            color="primary"
            sx={{ mb: 3 }}
            onClick={handleEditNavigation}
          >
            Edit {data?.name} Profile
          </Button>
        </Box>
        <Box>
          <Box className="pet-img-block" sx={{ position: { md: "absolute" } }}>
            <Box
              component="img"
              src={data?.profile_image}
              className="img-style img-style__cover"
            />
          </Box>
          <Box>
          <Typography
              variant="h3"
              
              sx={{ mb: 1, cursor: "pointer","&:hover": {
                color: "primary.main",
              }, }}
              onClick={navigateToGallery}
            >
              {data?.name}
            </Typography>
            <Typography variant="body1" sx={{ color: "info.darker" }}>
              {data?.description}
            </Typography>
            <Box sx={{ mt: 4 }}>
              <Box className="detail-row">
                <Typography variant="body1" sx={{ color: "info.darker" }}>
                  Kind of a pet
                </Typography>
                {/*@ts-ignore */}
                <Typography>{petTypeById[data?.pet_type_id]}</Typography>
              </Box>
              <Box className="detail-row">
                <Typography variant="body1" sx={{ color: "info.darker" }}>
                  Your Pet’s Gender
                </Typography>
                <Typography>
                  {data?.gender === "M" ? "Male" : "Female"}
                </Typography>
              </Box>
              <Box className="detail-row">
                <Typography variant="body1" sx={{ color: "info.darker" }}>
                  Your Pet’s Breed
                </Typography>
                <Typography>{breed}</Typography>
              </Box>
              <Box className="detail-row">
                <Typography variant="body1" sx={{ color: "info.darker" }}>
                  Your Pet’s Color
                </Typography>
                <Typography>{color}</Typography>
              </Box>
              <Box className="detail-row">
                <Typography variant="body1" sx={{ color: "info.darker" }}>
                  Outfit
                </Typography>
                <Typography>{marking}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </StyledPetEditDetails>
  );
}
export default PetEditDetails;
