import * as Yup from 'yup';

export const userProfileSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    mobile: Yup.string().required('Mobile is required').min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits'),
});

export const facebookLoginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Please enter a valid Email')
})
  export const contactSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Please enter a valid Email').required('Email is required'),
    message: Yup.string().required('Message is required'),
    // type: Yup.string().required('Type of Message is required'),
  });
  
