import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {  useVerifyEmailsyncMutation } from "../redux/auth/authApi";
import { useToast } from "../hooks/useToast";
import { GlobalRoutes } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState, persistor } from "../redux";
import { logout } from "../redux/auth/authStore";
import { setUserData } from "../redux/user/userStore";
import { removeAuthToken } from "../utils/sessions";

type ActivateProviderPropsType = {
  children: React.ReactNode;
};

const ActivateProvider: React.FC<ActivateProviderPropsType> = ({
  children,
}) => {
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const { uidb64, token } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [verifyEmailsync] = useVerifyEmailsyncMutation();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyUserEmail = async () => {
      try {
        const res:
          | {
            error: {
              data: {
                message: string;
              };
            };
          }
          | any = await verifyEmailsync({
            uidb64,
            token,
          });

        if (res?.error) {
          throw new Error(res?.error?.data?.message);
        } else {
          toast({
            title: "Account activated successfully",
            severity: "success",
          });
        }
        setIsValidToken(true);
        navigate(GlobalRoutes.LOGIN);
      } catch (err: any) {
        toast({
          title: err?.message || "Error activating account at the moment",
          severity: "error",
        });
        navigate(GlobalRoutes.REGISTER);
      }
    };
    if (isAuthenticated) {
      //Logout user first
      dispatch(logout());
      dispatch(setUserData({}));
      removeAuthToken();
      localStorage.clear();
      persistor.purge();
      navigate(GlobalRoutes.LOGIN);
      verifyUserEmail();
    } else {
      verifyUserEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isValidToken ? (
    <>{children}</>
  ) : (
    <Box
      sx={{
        minHeight: "495px",
        position: "relative",
      }}
    >
      <CircularProgress
        sx={{
          top: "50%",
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        color="primary"
        size={40}
      />
    </Box>
  );
};

export default ActivateProvider;
