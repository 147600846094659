import React from 'react';
import Popper from '@mui/material/Popper';
import { Box, Button } from '@mui/material';
import { StyledSocialShare } from './styles';
import ShareIcon from '@mui/icons-material/Share';

// SVG Icons
const FacebookIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="currentColor">
    <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
  </svg>
);

const TwitterIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor">
    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
  </svg>
);

interface ShareIconsProps {
  imageUrl: string;
  message: string | null | undefined;
  ide: number | null | undefined;
}

const ShareIcons: React.FC<ShareIconsProps> = ({ ide, imageUrl, message }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleShare = async (platform: 'facebook' | 'twitter') => {
    const formattedMessage = message ? message.trim().replace(/\s+/g, '') : '';

    // Replace with your actual server URL
    const url = process.env.REACT_APP_BACKEND_BASE_URI;
    const backendUrl = `${url}share/${ide}/${platform}`;
    console.log(backendUrl)
    let shareUrl = '';
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${backendUrl}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(backendUrl)}`;
        break;
      default:
        return;
    }

    if (shareUrl) {
      window.open(shareUrl, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <StyledSocialShare>
      <Button
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        sx={{ color: 'info.light', "&:hover": { color: "primary.main", } }}
        className="share-btn rounded-btn"
      >
        <ShareIcon />
      </Button>
      <Popper id={id} open={open} anchorEl={anchorEl} className="share-icons">
        <Box className="share-list">
          <Button onClick={() => handleShare('facebook')} aria-label="Share on Facebook">
            <FacebookIcon />
          </Button>
          <Button onClick={() => handleShare('twitter')} aria-label="Share on Twitter">
            <TwitterIcon />
          </Button>
        </Box>
      </Popper>
    </StyledSocialShare>
  );
};

export default ShareIcons;
