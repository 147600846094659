import { useNavigate } from 'react-router-dom';
import { GlobalRoutes } from '../../../types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Box, Typography } from '@mui/material';

function PaymentSuccessPage() {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ padding: '6rem 1.5rem', textAlign: 'center' }}
    >
      <Box
      className="container-wrap"
        sx={{
          background: 'info.lighter',
          p: 4,
          pb: 5,
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }} color="success.main">
          <CheckCircleIcon
            sx={{ fontSize: '1.9rem', top: '4px', position: 'relative', mr: 1 }}
          />
          Subscription successful!
        </Typography>
        <Typography variant="body1">
        Thank you for your purchase! Your subscription has been processed successfully. An invoice will be sent to you shortly!
        </Typography>
        <Box>
          <Button
            variant="contained"
            sx={{ mt: 4, mx: 2 }}
            color="primary"
            onClick={() => navigate(GlobalRoutes.ACCOUNT_PROFILE)}
          >
            Go to Dashboard
          </Button>
          <Button
            variant="contained"
            sx={{ mx: 2, mt: 4 }}
            color="primary"
            onClick={() => navigate(GlobalRoutes.ACCOUNT_PROFILE)}
          >
            Back to Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentSuccessPage;
