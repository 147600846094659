

import { IAddPetPublic, IAddPetResponse } from "../../interfaces/pet";
import { appApi } from "../apis/apiSlice";

const authApiWithTag = appApi.enhanceEndpoints({
    addTagTypes: ["PETS"]
});

export const authApi = authApiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        addPet: builder.mutation<any, any>({
            query: (formData) => {
                return {
                    url: '/pet/create',
                    method: "POST",
                    body: formData,
                };
            },
            invalidatesTags: ["PETS"],
        }),
        generatePetImage: builder.mutation<any, any>({
            query: ({ description }) => {
                return {
                    url: '/text-to-image',
                    method: "POST",
                    body: { description },
                };
            },
            invalidatesTags: ["PETS"],
        }),
        petQuestions: builder.query<any, void>({
            query: () => "/pet-detail-question/",
        }),

        fetchPet: builder.query<IAddPetResponse, void>({
            query: () => "pets/",
            providesTags: ["PETS"]
        }),
        fetchPetBreedsByType: builder.query<any, any>({
            query: (id: number) => ({
                url: `pet/breeds/${id}`,
                method: 'GET',
                keepUnusedDataFor: 0,
                refetchOnMountOrArgChange: true,
            }),
            providesTags: ['PETS'],
        }),
        fetchPetMarkingByType: builder.query<any, any>({
            query: (id: number) => ({
                url: `pet/markings/${id}`,
                method: 'GET',
                keepUnusedDataFor: 0,
                refetchOnMountOrArgChange: true,
            }),
            providesTags: ['PETS'],
        }),
        fetchPetColorsByType: builder.query<any, any>({
            query: (id: number) => ({
                url: `pet/color/${id}`,
                method: 'GET',
                keepUnusedDataFor: 0,
                refetchOnMountOrArgChange: true,
            }),
            providesTags: ['PETS'],
        }),
        fetchPetSizes: builder.query<any, any>({
            query: () => ({
                url: 'pet/size',
                method: 'GET',
            }),
        }),
        fetchAllPets: builder.query<any, any>({
            query: () => ({
                url: '/pet/list',
                method: 'GET',
                keepUnusedDataFor: 0,
                refetchOnMountOrArgChange: true,
            }),
        }),
        updatePet: builder.mutation<any, any>({
            query: ({ id, formData }) => ({
                url: `/pet/pets/${id}/`,
                method: 'PUT',
                body: formData,
            }),
        }),
        fetchPetById: builder.query<any, any>({
            query: (id: number) => ({
                url: `/pet/pets/${id}/`,
                method: 'GET',
                keepUnusedDataFor: 0,
                refetchOnMountOrArgChange: true,
            })
        }),
        deletePet: builder.mutation<any, any>({
            query: (id: number | string) => ({
                url: `/pet/pets/${id}/`,
                method: 'DELETE',
            }),
        }),
        fetchPetGalleryData: builder.query<any, any>({
            query: ({ petIds, searchQ, page, page_size }) => {
                return {
                    url: `/pet/user-pets-gallery/?pet_ids=${petIds}&search_query=${searchQ}&page=${page}&page_size=${page_size}`,
                    method: 'GET',
                    keepUnusedDataFor: 0,
                    refetchOnMountOrArgChange: true,
                };
            },
        }),
        fetchPublicGalleryData: builder.query<any, any>({
            query: ({ searchQ, page, page_size }) => {
                return {
                    url: `/pet/public-prompts/?page=${page}&page_size=${page_size}&search_query=${searchQ}`,
                    method: 'GET',
                    keepUnusedDataFor: 0,
                    refetchOnMountOrArgChange: true,

                };
            },
        }),
        addPetPublic: builder.mutation<IAddPetPublic, { user_prompt_id: number | null | undefined,is_public:number }>({
            query: ({ user_prompt_id,is_public }) => {
            
                return {
                    url: `/pet/make-prompt-public`,
                    method: 'POST',
                    body: {
                        user_prompt_id,
                        is_public
                    },
                };
            },
        }),

        addUserAction: builder.mutation<any, any>({
            query: (payload: any) => ({
                url: '/pet/store-reaction/',
                method: 'POST',
                body: payload,
            }),
        }),
        fetchPetCharacteristics: builder.query<any, any>({
            query: (id: number) => ({
                url: '/pet/pet-characteristics/' + id,
                method: 'GET',
                keepUnusedDataFor: 0,
                refetchOnMountOrArgChange: true,
            })
        })
    }),
});

export const {
    useFetchPetQuery,
    useAddPetMutation,
    useGeneratePetImageMutation,
    usePetQuestionsQuery,
    useFetchPetByIdQuery,
    useUpdatePetMutation,
    useFetchPetBreedsByTypeQuery,
    useFetchPetMarkingByTypeQuery,
    useFetchPetColorsByTypeQuery,
    useFetchPetSizesQuery,
    useFetchAllPetsQuery,
    useDeletePetMutation,
    useLazyFetchPetGalleryDataQuery,
    useLazyFetchPetBreedsByTypeQuery,
    useLazyFetchPetColorsByTypeQuery,
    useLazyFetchPetMarkingByTypeQuery,
    useAddUserActionMutation,
    useFetchPetCharacteristicsQuery,
    useFetchPublicGalleryDataQuery,
    useAddPetPublicMutation
} = authApi;
