import { Box, Button, Typography } from "@mui/material";
import { UserDataContainer } from "./styles";
import { useNavigate } from "react-router-dom";
import { GlobalRoutes } from "../../../types";
import {
  useFetchNeighbourhoodsQuery,
  useFetchResidencesQuery,
  useFetchStateCitiesQuery,
  useFetchUserQuery,
} from "../../../redux/user/userApi";
import { format, parseISO } from "date-fns";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";

type ProfilePropsType = {};

const Profile: React.FC<ProfilePropsType> = () => {
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.user);
  const { data: residences } = useFetchResidencesQuery({});
  const { data: neighbourhoods } = useFetchNeighbourhoodsQuery({});
  let cityAndState = userData?.other_state || userData?.city_state_name;

  const handleEditNavigation = () => {
    navigate(GlobalRoutes.EDIT_PROFILE);
  };

  let residence = "";
  let neighbourhood = "";
  if (residences && userData) {
    residence = residences?.data.filter(
      (r: { id: any }) => r.id === userData?.type_of_residence
    )[0];
  }

  if (neighbourhoods && userData) {
    neighbourhood = neighbourhoods?.data.filter(
      (n: { id: any }) => n.id === userData?.type_of_neighborhood
    )[0];
  }

  return (
    <>
      <Helmet>
        <title> Profile | MypetsText </title>
      </Helmet>
      <Box sx={{ width: "100%" }}>
        <Box>
          <UserDataContainer alignItems={"center"}>
            <Box
              className="info-container"
              sx={{ mb: { xs: 4, md: 6, lg: 8 } }}
            >
              <Box
                component="img"
                src={userData?.profile_pic || ""}
                sx={{
                  marginLeft: { xs: "auto", md: "0" },
                  marginRight: { xs: "auto", md: "0" },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: { md: "calc(100% - 225px)" },
                mb: { xs: 4, md: 6, lg: 8 },
              }}
            >
              <Button
                variant="contained"
                onClick={handleEditNavigation}
                className="edit-btn"
                sx={{ marginLeft: "auto", mb: 1 }}
              >
                Edit
              </Button>
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: 28, sm: 30, md: 32, lg: 35 },
                  width: { xs: "100%", md: "auto" },
                  color: "primary.dark",
                }}
              >
                {`${userData?.user?.first_name || ""} ${
                  userData?.user?.last_name || ""
                }`}
              </Typography>
            </Box>
          </UserDataContainer>
          <UserDataContainer>
            <Box className="info-container">
              <Typography
                sx={{ color: "info.darker", mb: { xs: 2, lg: 4 } }}
                variant="h5"
              >
                Contact Number
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 2, lg: 4 },
                  color: "primary.dark",
                }}
              >
                {userData?.contact_number
                  ? `(${userData.contact_number.slice(
                      0,
                      3
                    )}) ${userData.contact_number.slice(
                      3,
                      6
                    )}-${userData.contact_number.slice(6)}`
                  : ""}
              </Typography>
            </Box>
          </UserDataContainer>
          <UserDataContainer>
            <Box className="info-container">
              <Typography
                sx={{ color: "info.darker", mb: { xs: 2, lg: 4 } }}
                variant="h5"
              >
                Gender
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 2, lg: 4 },
                  color: "primary.dark",
                }}
              >
                {userData?.gender ? (userData.gender === "M" ? "Male" : "Female") : ""}
              </Typography>
            </Box>
          </UserDataContainer>
          <UserDataContainer>
            <Box className="info-container">
              <Typography
                sx={{ color: "info.darker", mb: { xs: 2, lg: 4 } }}
                variant="h5"
              >
                Email Address
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 2, lg: 4 },
                  color: "primary.dark",
                }}
              >
                {userData?.user?.email || ""}
              </Typography>
            </Box>
          </UserDataContainer>
          <UserDataContainer>
            <Box className="info-container">
              <Typography
                sx={{ color: "info.darker", mb: { xs: 2, lg: 4 } }}
                variant="h5"
              >
                D.O.B
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 2, lg: 4 },
                  color: "primary.dark",
                }}
              >
                {userData?.date_of_birth
                  ? format(parseISO(userData?.date_of_birth), "MM-dd-yyyy")
                  : ""}
              </Typography>
            </Box>
          </UserDataContainer>
          <UserDataContainer>
            <Box className="info-container">
              <Typography
                sx={{ color: "info.darker", mb: { xs: 2, lg: 4 } }}
                variant="h5"
              >
                Type of Residence
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 2, lg: 4 },
                  color: "primary.dark",
                  textAlign: "left",
                }}
              >
                {/**@ts-ignore */}
                {residence?.name}
              </Typography>
            </Box>
          </UserDataContainer>
          <UserDataContainer>
            <Box className="info-container">
              <Typography
                sx={{ color: "info.darker", mb: { xs: 2, lg: 4 } }}
                variant="h5"
              >
                Type of Neighborhood
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 2, lg: 4 },
                  color: "primary.dark",
                  textAlign: "left",
                }}
              >
                {/**@ts-ignore */}
                {neighbourhood?.name}
              </Typography>
            </Box>
          </UserDataContainer>
          <UserDataContainer>
            <Box className="info-container">
              <Typography
                sx={{ color: "info.darker", mb: { xs: 2, lg: 4 } }}
                variant="h5"
              >
                Address
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 2, lg: 4 },
                  color: "primary.dark",
                }}
              >
                {userData?.address}
              </Typography>
            </Box>
          </UserDataContainer>
          <UserDataContainer>
            <Box className="info-container">
              <Typography
                sx={{ color: "info.darker", mb: { xs: 2, lg: 4 } }}
                variant="h5"
              >
                City and State
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 2, lg: 4 },
                  color: "primary.dark",
                }}
              >
                {cityAndState}
              </Typography>
            </Box>
          </UserDataContainer>
          <UserDataContainer>
            <Box className="info-container">
              <Typography
                sx={{ color: "info.darker", mb: { xs: 2, lg: 4 } }}
                variant="h5"
              >
                Zip Code 
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  mb: { xs: 2, lg: 4 },
                  color: "primary.dark",
                }}
              >
                {userData?.zip_code}
              </Typography>
            </Box>
          </UserDataContainer>
        </Box>
      </Box>
    </>
  );
};

export default Profile;
