import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Label,
  ImageInput,
  UploadProfilePic,
  RenderImage,
  TriggerModal,
  DeleteIcon,
  EditImageContainer,
  EditDeleteImgContainer,
} from "./styles";
import { FormikProps, useFormik } from "formik";
import { petSchema } from "../../validators/schemas/petSchemas";
import DetailSearchModal from "../DetailSearchModal";
import { Media } from "../../config/media";
import { Stepper, petSearchData, petTypes } from "../../config/constants";
import {
  useAddPetMutation,
  useFetchPetBreedsByTypeQuery,
  useFetchPetCharacteristicsQuery,
  useFetchPetColorsByTypeQuery,
  useFetchPetMarkingByTypeQuery,
  useFetchPetSizesQuery,
  useUpdatePetMutation,
} from "../../redux/pet/petApi";
import { PetSize } from "../../types/pet";
import { useToast } from "../../hooks/useToast";
import { LoadingButton } from "@mui/lab";
import CustomHelperText from "../CustomHelperText";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { GlobalRoutes } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { setProfileCompleted } from "../../redux/stepper/stepperStore";
import StepperModal from "../StepperModal";
import RequiredAsterisk from "../RequiredAsterisk";

type PetPropsType = {
  action?: string;
  data?: any;
  editLoader?: boolean;
  petId?: string | undefined;
  path?: string;
  edit?: boolean;
};

type PetSelectedImgType = {
  id: number;
  reference_image: string;
  name: string;
};
type PetFormValuesType = {
  profile_image: string | undefined | File;
  name: string;
  gender: string;
  pet_type_id: number | undefined;
  primary_breed_id: number | undefined;
  secondary_breed_id?: number | undefined;
  color_ids: string;
  marking_ids: string;
  description: string;
  size: string;
  unique_characteristics: string;
};

const PetForm: React.FC<PetPropsType> = ({
  action = "create",
  data,
  editLoader,
  petId,
  edit,
  path,
}) => {
  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [imgUrl, setImgUrl] = useState<string>("");
  const [selectedBreedImages, setSelectedBreedImages] = useState<
    PetSelectedImgType[]
  >([]);
  const [selectedMarkingImages, setSelectedMarkingImages] = useState<
    PetSelectedImgType[]
  >([]);
  const [selectedColorImages, setSelectedColorImages] = useState<
    PetSelectedImgType[]
  >([]);
  const { data: sizes } = useFetchPetSizesQuery({});
  const [createPet, { isLoading }] = useAddPetMutation();
  const [updatePet, { isLoading: isUpdating }] = useUpdatePetMutation();
  const toast = useToast();
  const { completed } = useSelector((state: RootState) => state.stepper);
  const [showStepperModal, setShowStepperModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [settled, setSettled] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<{
    title: string;
    searchingFor: string;
  } | null>(null);

  const {
    values,
    setFieldValue,
    handleBlur,
    handleSubmit,
    handleChange,
    resetForm,
    errors,
    touched,
  }: FormikProps<PetFormValuesType> = useFormik({
    initialValues: {
      profile_image: undefined,
      name: "",
      gender: "M",
      pet_type_id: 1,
      primary_breed_id: undefined,
      color_ids: "",
      marking_ids: "",
      description: "",
      size: "",
      unique_characteristics: "",
    },
    validationSchema: petSchema,
    onSubmit: submitHandler,
  });
  const { data: breeds, isLoading: fetchingBreeds } =
    useFetchPetBreedsByTypeQuery(values?.pet_type_id);
  const { data: colors, isLoading: fetchingColors } =
    useFetchPetColorsByTypeQuery(values?.pet_type_id);
  const { data: markings, isLoading: fetchingMarkings } =
    useFetchPetMarkingByTypeQuery(values?.pet_type_id);
  /**
   * if editing pet,
   * then initializing form with the data
   */
  useEffect(() => {
    if (data) {
      Object.keys(petSchema?.fields).forEach((key: string) => {
        setFieldValue(`${key}`, data[key]);
        if (key === "profile_image") {
          setImgUrl(data[key]);
        }
      });
      setSettled(true);
    }
  }, [data]);

  useEffect(() => {
    if (values?.pet_type_id === data?.pet_type_id) {
      Object.keys(petSchema?.fields).forEach((key: string) => {
        setFieldValue(`${key}`, data[key]);
        if (key === 'profile_image') {
          setImgUrl(data[key]);
        }
      });
    }
  }, [values?.pet_type_id]);

  /**
   * Setting images for breeds, colors, markings
   */
  useEffect(() => {
    initSelectedImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, breeds, markings, colors]);

  useEffect(() => {
    if (values?.pet_type_id === data?.pet_type_id) {
      initSelectedImages();
    } else if (settled) {
      clearFields();
    } else if (!data) {
      setFieldValue('color_ids', '');
      setFieldValue('marking_ids', '');
      setFieldValue('primary_breed_id', '');
      setFieldValue('secondary_breed_id', '');
      setSelectedBreedImages([]);
      setSelectedColorImages([]);
      setSelectedMarkingImages([]);
    }
  }, [values?.pet_type_id]);

  async function submitHandler(data: PetFormValuesType) {
    const formData = new FormData();
    Object.keys(data).forEach(async (key: string) => {
      //@ts-ignore
      const value = values[key];
      if (value) {
        formData.append(key, value);
      }
    });
    try {
      let res: any;
      if (action === "create") {
        res = await createPet(formData);
      } else if (action === "update") {
        if (typeof data?.profile_image === "string") {
          formData.delete("profile_image");
        }
        res = await updatePet({
          id: petId,
          formData,
        });
      }
      //@ts-ignore
      if (res?.error) {
        throw new Error(
          `Unable to ${action === "create" ? "add" : "update"
          } pet at the moment`
        );
      } else {
        toast({
          title: `Pet ${action === "create" ? "added" : "updated"
            } successfully`,
        });
        // Updating profile stepper
        if (completed < Stepper.MY_PETS) {
          setShowStepperModal(true);
        } else {
          navigate(GlobalRoutes.ACCOUNT_MY_PETS, {
            state: { refetch: action === "create" ? false : true },
          });
        }
      }
    } catch (err: any) {
      toast({
        title:
          err?.message ||
          `Unable to ${action === "create" ? "add" : "update"
          } pet at the moment`,
        severity: "error",
      });
    }
  }

  function initSelectedImages() {
    if (data) {
      //Settings breed images
      if (data?.primary_breed_id && breeds?.data) {
        const breedIdsArr: number[] = [];
        const breedImages: PetSelectedImgType[] = [];
        breedIdsArr.push(data.primary_breed_id);
        if (data?.secondary_breed_id) {
          breedIdsArr.push(data.secondary_breed_id);
        }
        if (breedIdsArr.length) {
          breeds.data.forEach(({ id, reference_image, name }: any) => {
            if (breedIdsArr.includes(id)) {
              breedImages.push({
                id,
                reference_image: reference_image || "",
                name,
              });
            }
          });
          setSelectedBreedImages(breedImages);
        }
      }
      //Setting color images
      if (data?.color_ids && colors?.data) {
        const colorImages: PetSelectedImgType[] = [];
        const colorIdsArr = data?.color_ids.split(",");
        colors.data.forEach(({ id, reference_image, name }: any) => {
          if (colorIdsArr.includes(`${id}`)) {
            colorImages.push({
              id,
              reference_image: reference_image || "",
              name,
            });
          }
        });
        if (values?.color_ids || values?.pet_type_id === data?.pet_type_id) {
          setSelectedColorImages(colorImages);
        }
      }
      //Setting marking images
      if (data?.marking_ids && markings?.data) {
        const markingImages: PetSelectedImgType[] = [];
        const markingIdsArr = data?.marking_ids.split(",");
        markings.data.forEach(({ id, reference_image, name }: any) => {
          if (markingIdsArr.includes(`${id}`)) {
            markingImages.push({
              id,
              reference_image: reference_image || "",
              name,
            });
          }
        });
        setSelectedMarkingImages(markingImages);
      }
    }
  }

  const handleSingleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const { name } = e.target;
      if (file) {
        setFieldValue(name, file);
        const reader = new FileReader();
        reader.onload = (e: any) => {
          if (e.target.result) {
            setImgUrl(e.target.result);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const removeImageHandler = (fieldName: string) => {
    setFieldValue(fieldName, "");
    setImgUrl("");
  };

  const handleColorSelector = () => {
    setShowSearchModal(true);
    setSearchParams(petSearchData["color"]);
  };

  const handleBreedSelector = () => {
    setShowSearchModal(true);
    setSearchParams(petSearchData["breed"]);
  };

  const handleMarkingSelector = () => {
    setShowSearchModal(true);
    setSearchParams(petSearchData["marking"]);
  };

  const hasFieldError = (field: string) => {
    //@ts-ignore
    return Boolean(errors[field] && touched[field]);
  };

  const handleCancel = () => {
    navigate(path ? path : state?.path ? state.path : GlobalRoutes.ACCOUNT_MY_PETS);
  }

  function clearFields() {
    const petTypeId = values?.pet_type_id;
    resetForm();
    setFieldValue('pet_type_id', petTypeId);
    setSelectedBreedImages([]);
    setSelectedColorImages([]);
    setSelectedMarkingImages([]);
  }

  const handleCardDeletion = (e: any, name: string, id?: number) => {
    switch (name) {
      case 'primary_breed':
        setFieldValue('primary_breed_id', '');
        setSelectedBreedImages([...selectedBreedImages.slice(1, 2)]);
        break;
      case 'secondary_breed':
        setFieldValue('secondary_breed_id', '');
        setSelectedBreedImages([...selectedBreedImages.slice(0, 1)]);
        break;
      case 'color':
        const updatedColors = selectedColorImages.filter((color) => color.id !== id);
        setSelectedColorImages(updatedColors);
        let colorIds = '';
        updatedColors.forEach((color, index) => {
          // Last item
          if (index === updatedColors?.length - 1) {
            colorIds += `${color?.id}`;
          } else {
            colorIds += `${color?.id},`;
          }
        });
        setFieldValue('color_ids', colorIds);
        break;
      case 'marking':
        const updatedMarkings = selectedMarkingImages.filter((marking) => marking.id !== id);
        setSelectedMarkingImages(updatedMarkings);
        let markingIds = '';
        updatedMarkings.forEach((marking, index) => {
          // Last item
          if (index === updatedMarkings?.length - 1) {
            markingIds += `${marking?.id}`;
          } else {
            markingIds += `${marking?.id},`;
          }
        });
        setFieldValue('marking_ids', markingIds);
        break;
    }
    e.stopPropagation();
  }

  return (
    <Box sx={{ mt: 3, textAlign: 'left' }}>
      {edit === false ? <Typography variant="h3" sx={{ mb: 5, pt: 2 }}>
        Enter Basic Details
      </Typography> : null}
      <Box>
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems={"center"} spacing={{ xs: 1, md: 2 }}>
                <Grid item md={4} sx={{ mb: 2 }}>
                  <Label>Upload a profile picture for your pet</Label>
                  <RequiredAsterisk />
                </Grid>
                <Grid item md={8} xs={12} sx={{ mb: 2 }}>
                  <ImageInput
                    type="file"
                    accept="image/jpeg, image/png"
                    id="profile_image"
                    name="profile_image"
                    onChange={handleSingleFileChange}
                  />
                  {values?.profile_image ? (
                    <EditImageContainer>
                      <RenderImage src={imgUrl} />
                      <EditDeleteImgContainer className="edit-delete-img-container">
                        <Stack
                          direction="row"
                          gap={1}
                          sx={{ cursor: "pointer", alignItems: "center" }}
                          onClick={() => removeImageHandler("profile_image")}
                        >
                          <DeleteIcon />
                        </Stack>
                      </EditDeleteImgContainer>
                    </EditImageContainer>
                  ) : (
                    <label htmlFor="profile_image">
                      <UploadProfilePic className="upload-file">
                        <FileUploadIcon />
                        <Typography variant="caption">Upload Your Pet's Profile Photo</Typography>
                      </UploadProfilePic>
                    </label>
                  )}
                  {hasFieldError("profile_image") && (
                    <CustomHelperText title={errors.profile_image} />
                  )}
                  {!values?.profile_image && (
                    <Typography
                      variant="body2"
                      sx={{
                        mt: 2,
                        fontWeight: 300,
                        fontSize: "0.938rem",
                        lineHeight: "1.375rem",
                      }}
                    >
                      Upload a profile picture for your pet of minimum 2MB in
                      .jpeg or .png{" "}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid
                  item
                  md={4}
                  xs={12}
                  sx={{ mb: 2 }}
                  className="vertical-center"
                >
                  <Label>Enter your pet's name</Label>
                  <RequiredAsterisk />
                </Grid>
                <Grid item md={8} xs={12} sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    placeholder="Enter Name"
                    name="name"
                    value={values?.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.name && touched.name)}
                    helperText={
                      Boolean(errors.name && touched.name) && errors.name
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                  <Label>What is your pet's gender</Label>
                  <RequiredAsterisk />
                </Grid>
                <Grid item md={8} xs={12} sx={{ mb: 2 }}>
                  <FormControl className="pets-gender">
                    <RadioGroup
                      row
                      aria-labelledby="gender-row-radio-buttons-group-label"
                      name="gender"
                      value={values?.gender}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="M"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="F"
                        control={<Radio />}
                        label="Female"
                      />
                    </RadioGroup>
                    {hasFieldError("gender") && (
                      <CustomHelperText title={errors.gender} />
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid
                  item
                  md={4}
                  xs={12}
                  sx={{ mb: 2 }}
                  className="vertical-center"
                >
                  <Label htmlFor="pet">What kind of pet do you have</Label>
                  <RequiredAsterisk />
                </Grid>
                <Grid item md={8} xs={12} sx={{ mb: 2 }}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      value={values?.pet_type_id}
                      name="pet_type_id"
                      input={<OutlinedInput />}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {petTypes.map(({ key, value }) => (
                        <MenuItem disabled={key === 3} value={key} key={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                  <Label>What is your pet's breed</Label>
                  <RequiredAsterisk />
                  <Typography variant="body2" sx={{
                    color: 'gray', fontSize: {
                      xs: '8px',
                      sm: '8px',
                      md: '8px',
                      lg: '11px',
                      xl: '12px',
                    }, mt: 0.5, mb: 0.5
                  }}>
                    Select a standard breed or choose two individual breeds for a mixed breed.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8} sx={{ mb: { xs: 0, md: 2 } }}>
                  <Grid item md={12} xs={12} sx={{ mb: { xs: 0, md: 2 } }}>
                    <Grid container spacing={2}>
                      {selectedBreedImages.map((breed, index) => (
                        <Grid
                          key={index}
                          item
                          xs={14}
                          sm={8}
                          lg={4}
                          sx={{ mb: { xs: 2 } }}
                        >
                          <TriggerModal style={{ height: "100%", width: "100%" }} onClick={() => handleBreedSelector}>
                            <Box className="pet-img__contain" style={{ width: "100%" }}>
                              <DeleteIcon sx={{ ml: 'auto', color: '#000' }} onClick={(e) => handleCardDeletion(e, index === 0 ? "primary_breed" : "secondary_breed")} />
                              <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} sx={{ marginBottom: "10px" }}>
                                <Typography component="span" sx={{ fontSize: "10px !important", fontWeight: "300", color: "info.darker" }}>
                                  {index === 0 ? "Primary Breed" : "Secondary Breed"}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: "15px !important", maxWidth: "100px" }}>
                                  {breed.name}
                                </Typography>
                              </Stack>
                              <Box
                                component="img"
                                className="pet-img__wrap"
                                src={breed.reference_image || (values?.pet_type_id === 1 ? Media.DogPlaceholderImage : Media.CatPlaceholderImage)}
                                sx={{ width: "100%", height: "auto", maxHeight: "200px", objectFit: "cover" }} // Adjust the width and height here
                              />
                            </Box>
                          </TriggerModal>
                          {hasFieldError(index === 0 ? 'primary_breed_id' : 'secondary_breed_id') && (
                            <CustomHelperText title={errors[index === 0 ? 'primary_breed_id' : 'secondary_breed_id']} />
                          )}
                        </Grid>
                      ))}
                      {selectedBreedImages.length < 2 && (
                        <Grid item xs={12} sm={6} lg={4} sx={{ mb: { xs: 2 } }}>
                          <TriggerModal style={{ height: "100%", width: "100%" }} onClick={handleBreedSelector}>
                            <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                              <Typography component="span" sx={{ color: "info.darker", display: "inline-block", paddingTop: "2.1rem" }}>
                                Click here to choose your pet’s {selectedBreedImages.length === 0 ? "primary" : "secondary"} breed
                              </Typography>
                              <Box
                                component="img"
                                alt="pet placeholder img"
                                src={Media.PetPlaceholderImage}
                                className="img-style__contain"
                                sx={{ width: "80px", height: "120px", margin: "10px auto 0" }} // Adjust the width and height here
                              />
                            </Box>
                          </TriggerModal>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>





                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                {/* Label and Required Asterisk */}
                <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                  <Label>What is your pet's color</Label>
                  <RequiredAsterisk />
                  <Typography variant="body2" sx={{
                    color: 'gray', fontSize: {
                      xs: '8px',
                      sm: '8px',
                      md: '8px',
                      lg: '10px',
                      xl: '12px',
                    }, mt: 0.5, mb: 0.5
                  }}>
                    You can select up to 3 colors.
                  </Typography>
                </Grid>

                <Grid item xs={12} md={8} sx={{ mb: { xs: 0, md: 2 } }}>
                  <Grid container spacing={2}>
                    {/* Display Selected Items */}
                    {selectedColorImages && selectedColorImages.length > 0 && (
                      selectedColorImages.map(({ id, name = "", reference_image = "" }: any) => (
                        <Grid
                          key={id}
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          sx={{ mb: { xs: 2 } }}
                        >
                          <TriggerModal onClick={handleColorSelector}>
                            <Box className="pet-img__contain">
                              <DeleteIcon
                                sx={{
                                  ml: 'auto',
                                  color: '#000'
                                }}
                                onClick={(e) => handleCardDeletion(e, 'color', id)}
                              />
                              <Stack
                                direction="row"
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                sx={{ marginBottom: "10px" }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "15px !important",
                                    marginLeft: "auto",
                                  }}
                                >
                                  {name}
                                </Typography>
                              </Stack>
                              <Box
                                component="img"
                                className="img-style__contain pet-img__wrap color-field"
                                src={reference_image}
                              />
                            </Box>
                          </TriggerModal>
                        </Grid>
                      ))
                    )}

                    {/* Display Placeholder */}
                    {(selectedColorImages.length < 3) && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        sx={{ mb: { xs: 2 }, display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <TriggerModal onClick={handleColorSelector}>
                          <>
                            <Typography
                              component="span"
                              sx={{
                                color: "info.darker",
                                display: "inline-block",
                                paddingTop: "2.1rem",
                              }}
                            >
                              Click here to choose your pet’s color
                            </Typography>
                            <Box
                              component="img"
                              alt="Question icon"
                              src={Media.QuestionIconImage}
                              className="img-style__contain"
                              sx={{
                                width: "17px",
                                height: "23px",
                                margin: "40px auto 0",
                              }}
                            />
                          </>
                        </TriggerModal>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                  <Label>What markings does your pet have</Label>
                  <RequiredAsterisk />
                  <Typography variant="body2" sx={{
                    color: 'gray', fontSize: {
                      xs: '8px',
                      sm: '8px',
                      md: '8px',
                      lg: '10px',
                      xl: '12px',
                    }, mt: 0.5, mb: 0.5
                  }}>
                    You can select up to 2 markings.
                  </Typography>
                </Grid>
                <Grid item md={8} xs={12} sx={{ mb: { xs: 0, md: 2 } }}>
                  <Grid container spacing={2}>
                    {/* Display Selected Marking Images */}
                    {selectedMarkingImages && selectedMarkingImages.length > 0 && (
                      selectedMarkingImages.map(({ id, name = "", reference_image = "" }: any) => (
                        <Grid
                          key={id}
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                          sx={{ mb: { xs: 2 } }}
                        >
                          <TriggerModal onClick={handleMarkingSelector}>
                            <Box className="pet-img__contain">
                              <DeleteIcon
                                sx={{ ml: 'auto', color: '#000' }}
                                onClick={(e) => handleCardDeletion(e, 'marking', id)}
                              />
                              <Stack
                                direction="row"
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                sx={{ marginBottom: "10px" }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "15px !important",
                                    marginLeft: "auto",
                                  }}
                                >
                                  {name}
                                </Typography>
                              </Stack>
                              <Box
                                component="img"
                                className="img-style__contain pet-img__wrap"
                                src={reference_image}
                              />
                            </Box>
                          </TriggerModal>
                        </Grid>
                      ))
                    )}

                    {/* Display Placeholder */}
                    {selectedMarkingImages && selectedMarkingImages.length < 2 && (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        sx={{ mb: { xs: 2 }, display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <TriggerModal onClick={handleMarkingSelector}>
                          <>
                            <Typography
                              component="span"
                              sx={{
                                color: "info.darker",
                                display: "inline-block",
                                paddingTop: "2.1rem",
                              }}
                            >
                              Click here to choose your pet’s marking
                            </Typography>
                            <Box
                              component="img"
                              alt="Question icon"
                              src={Media.QuestionIconImage}
                              className="img-style__contain"
                              sx={{
                                width: "17px",
                                height: "23px",
                                margin: "40px auto 0",
                              }}
                            />
                          </>
                        </TriggerModal>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                  <Label>Description</Label>
                  <RequiredAsterisk />
                </Grid>
                <Grid item md={8} xs={12} sx={{ mb: 2 }}>
                  <TextField
                    fullWidth
                    multiline
                    placeholder="Write here..."
                    name="description"
                    value={values?.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={hasFieldError("description")}
                    helperText={
                      hasFieldError("description") && errors.description
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item md={4} xs={12} sx={{ mb: 2 }}>
                  <Label>Describe unique characteristics about your pet</Label>
                </Grid>
                <Grid item md={8} xs={12} sx={{ mb: 2 }}>
                  <TextField
                    value={values?.unique_characteristics}
                    name="unique_characteristics"
                    onChange={handleChange}
                    fullWidth
                    placeholder="Characteristics..."
                    onBlur={handleBlur}
                    error={Boolean(errors.unique_characteristics && touched.unique_characteristics)}
                    helperText={
                      Boolean(errors.unique_characteristics && touched.unique_characteristics) && errors.unique_characteristics
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={4} sx={{ mb: 2 }}>
                  <Label>Choose your pet's size</Label>
                  <RequiredAsterisk />
                </Grid>
                <Grid item xs={12} md={8} sx={{ mb: 2 }}>
                  <FormControl sx={{ width: "100%" }}>
                    <RadioGroup
                      name="size"
                      value={values?.size}
                      onChange={handleChange}
                    >
                      <Grid item xs={12}>
                        <Grid container>
                          {sizes?.data &&
                            sizes.data.map(
                              ({
                                id,
                                size,
                                low_pounds,
                                high_pounds,
                              }: PetSize) => (
                                <Grid
                                  item
                                  key={id}
                                  xs={6}
                                  md={4}
                                  sx={{ mb: { xs: 2, sm: 3, md: 4, lg: 5 } }}
                                >
                                  <FormControlLabel
                                    value={size}
                                    control={<Radio />}
                                    label={size}
                                    sx={{ ml: 0 }}
                                  />
                                  <Typography
                                    className="text-small"
                                    sx={{ marginLeft: "1.7rem" }}
                                  >
                                    ({low_pounds} - {high_pounds} lbs)
                                  </Typography>
                                </Grid>
                              )
                            )}
                        </Grid>
                        {hasFieldError("size") && (
                          <CustomHelperText title={errors.size} />
                        )}
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={4} sx={{ mb: 2 }}></Grid>
                <Grid item xs={12} md={8} sx={{ mb: 2 }}>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={isLoading || isUpdating}
                    sx={{ mr: 1, mb: 4 }}
                  >
                    {isLoading || isUpdating ? (
                      <CircularProgress
                        sx={{ ml: 2 }}
                        color="inherit"
                        size={24}
                      />
                    ) : action === "update" ? (
                      "Update"
                    ) : (
                      "Add Pet"
                    )}
                  </LoadingButton>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ mx: 1, mb: 4 }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/** Detail Select Modal */}
      {searchParams?.searchingFor === "breed" && showSearchModal && (
        <DetailSearchModal
          data={breeds}
          isLoading={fetchingBreeds}
          title={searchParams?.title}
          searchingFor={searchParams?.searchingFor}
          onClose={() => setShowSearchModal(false)}
          setFieldValue={setFieldValue}
          images={selectedBreedImages}
          setImages={setSelectedBreedImages}
          selectedData={[values?.primary_breed_id, values?.secondary_breed_id]}
          petTypeId={values?.pet_type_id}
        />
      )}
      {searchParams?.searchingFor === "marking" && showSearchModal && (
        <DetailSearchModal
          data={markings}
          isLoading={fetchingMarkings}
          title={searchParams?.title}
          searchingFor={searchParams?.searchingFor}
          onClose={() => setShowSearchModal(false)}
          setFieldValue={setFieldValue}
          images={selectedMarkingImages}
          setImages={setSelectedMarkingImages}
          selectedData={values?.marking_ids}
          petTypeId={values?.pet_type_id}
        />
      )}
      {searchParams?.searchingFor === "color" && showSearchModal && (
        <DetailSearchModal
          data={colors}
          isLoading={fetchingColors}
          title={searchParams?.title}
          searchingFor={searchParams?.searchingFor}
          onClose={() => setShowSearchModal(false)}
          setFieldValue={setFieldValue}
          images={selectedColorImages}
          setImages={setSelectedColorImages}
          selectedData={values?.color_ids}
          petTypeId={values?.pet_type_id}
        />
      )}

      {/* STEPPER MODAL */}
      {(showStepperModal) && (
        <StepperModal
          handleClose={() => setShowStepperModal(false)}
          show={showStepperModal}
          completedCount={Stepper.MY_PETS}
          sectionFilled="pet's"
          onSuccess={() => {
            dispatch(setProfileCompleted(Stepper.MY_PETS));
            navigate(GlobalRoutes.ACCCOUNT_SCHEDULE_MESSAGE);
          }}
          onSkip={() => {
            dispatch(setProfileCompleted(Stepper.MY_PETS));
          }}
        />
      )}
      {/** if editing pet, and pet data is fetching */}
      {editLoader && <Loader />}
    </Box>
  );
};

export default PetForm;
